export default {
    install (Vue) {
        Vue.validator = {
            /******** Validate **********
             * @function validateZipCode
             * @function validateEmail
             * @function validateContactNumber
             ****************************/
            validateZipCode: function(zipCode) {
                if (!zipCode) return false;
                
                return zipCode.match(/^[0-9]{5}/);
            },
            validateEmail: function(email) {
                // eslint-disable-next-line
                var rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return rex.test(email);
            },
            validateContactNumber: function(number) {
                // eslint-disable-next-line
                var rex = /^0(\d{9})$/;

                return rex.test(number);
            }
        };
    }
};
