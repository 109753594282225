export const config = {
  appname: 'crm_plus_backoffice',
  client_id: '2377666859112635', // App ID
  debug: false, //enable console.log
  bzbsAnalyticId: 291,
  env: 'prd',

  // ------------- Business Rule ----------------
  businessRule: {
    webFor: {
      TH: true, //Thailand
      PH: false, //Philippines
    },
    max: {
      photoSize: 3,
      phoneLength: 10,
    },
    key: {
      // googleMapApiKey: 'AIzaSyD-R7RAqCRIZl8DKrtq7iUSaWurOBOTl6M', //TH
      googleMapApiKey: 'AIzaSyCQtPl4zfjOdghhVENB17OUBFI9VhB1ZeY', //PH
      dataserviceKey:
        '9J2HO6CR1gTIH/uORDjjspo6aOYcVDCimOpe9FWNVpd6HF5GskQPVw==',
    },
    open: {
      package: true,
      Azure: true,
      payment: true,
      facebook: false,
      smarttarget_v2: false,
    },
    default: {
      terminalId: '0000001',
    },
    statusText: {
      active: 'Active',
      inactive: 'Inactive',
      waiting: 'Waiting',
      expired: 'Expired',
    },
    link: {
      connectLine:
        'https://buzzebees.blob.core.windows.net/config/crmplus/vdo-tutorial/connect-line/connect_line.mp4',
    },
    url: {
      merge_net: 'https://prd-web-crm-plus-backoffice.azurewebsites.net',
      merge: 'https://crmplusoffice.buzzebees.com',
      sso_net: 'https://prd-web-crm-plus-backoffice-2.azurewebsites.net',
      sso: 'https://crm-plus-backofficesso.buzzebees.com',
    },
  },

  // ------------- Development Settings ----------------
  agency_id: '',
  dataservice: 'https://stg-data-service-buzzebees.azurewebsites.net',
  bzbsAnalyticUrl: 'https://analytics-api-02.buzzebees.com/api/gate/',
  bzbsEdmUrl: 'https://edmmodule.buzzebees.com',
  bzbsUrl: 'https://apilpw2sso.buzzebees.com',
  bzbsModuleUrl: 'https://buzzcrmplusssomodule.buzzebees.com',
  bzbsBlobUrl: 'https://buzzebees.blob.core.windows.net',
  bzbsServiceUrl: 'https://api1servicecenter.buzzebees.com',
  bzbsConsumerUrl: 'https://crm-plus-consumersso.buzzebees.com',
  dataReportUrl: 'https://apilpwwallet.buzzebees.com',
  bzbsApiWallet: 'https://api1servicewallet.buzzebees.com',
  shopingCartUrl: 'https://shoppingcartservice20.buzzebees.com',
  shopingmoduleCartUrl: 'https://shoppingcartmodule20.buzzebees.com',
  shippingmoduleUrl: 'https://shippingmodule20.buzzebees.com',
}
