import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import Locale from '@/helper/locale.js'
import Account from '@/helper/AccountHelper.js'
import Datetime from '@/helper/DatetimeHelper.js'
export default {
  data() {
    return {
      lbl: null,
      locale: Locale,
      localecode: null,
    }
  },
  created() {
    this.getLbl()
    this.handleLoading(false)
    this.handleDashboard(false)
  },
  methods: {
    getImage(path, name) {
      path = path ? `/${path}` : ''
      return require(`../assets/images${path}/${name}.svg`)
    },
    dateFormat() {
      if (
        this.Date(this.date[0]) == this.Date() &&
        this.Date(this.date[1]) == this.Date()
      ) {
        return 'Today'
      } else if (
        this.Date(this.date[0]) == this.Date(null, 1, 'days') &&
        this.Date(this.date[1]) == this.Date(null, 1, 'days')
      ) {
        return 'Yesterday'
      } else if (
        this.Date(this.date[0]) == this.Date(null, 7, 'days') &&
        this.Date(this.date[1]) == this.Date()
      ) {
        return 'Last 7 Days'
      } else if (
        this.Date(this.date[0]) ==
          moment().startOf('week').format('DD/MM/YY') &&
        this.Date(this.date[1]) == this.Date()
      ) {
        return 'This Week'
      } else if (
        this.Date(this.date[0]) == this.Date(null, 30, 'days') &&
        this.Date(this.date[1]) == this.Date()
      ) {
        return 'Last 30 Days'
      } else if (
        this.Date(this.date[0]) == this.Date() &&
        this.Date(this.date[1]) == this.Date(null, 0, 'end')
      ) {
        return 'This Month'
      } else if (
        this.Date(this.date[0]) == this.Date(null, 1, 'start') &&
        this.Date(this.date[1]) == this.Date(null, 1, 'end')
      ) {
        return 'Last Month'
      } else {
        return this.Date(this.date[0]) + ' ~ ' + this.Date(this.date[1])
      }
    },
    Date(date = null, subtract = 0, type) {
      if (type == 'start') {
        return moment()
          .subtract(subtract, 'month')
          .startOf('month')
          .format('DD/MM/YY')
      } else if (type == 'end') {
        return moment()
          .subtract(subtract, 'month')
          .endOf('month')
          .format('DD/MM/YY')
      }
      if (date == null && !subtract) {
        return moment().format('DD/MM/YY')
      } else if (subtract) {
        return moment().subtract(subtract, type).format('DD/MM/YY')
      } else {
        return moment(date).format('DD/MM/YY')
      }
    },
    goto(page, query = {}) {
      this.$router.push({ name: page, query })
    },
    getLbl() {
      this.localecode = Locale.getLocaleShort()
      var store = localStorage.getItem('jsonLocale')
      if (this.localecode) {
        this.lbl = JSON.parse(store)[this.localecode]
      } else {
        this.checkLangStorage().then(() => {
          this.getLbl()
        })
      }
    },
    setLang(locale) {
      Locale.setLocale(locale)
      return new Promise((resolve, reject) => {
        if (Account.isLogin()) {
          Account.apiUpdateProfileLocale(locale)
            .then(profile => {
              if (this.profile) this.profile = profile
              resolve({ locale: locale, profile: profile })
            })
            .catch(err => {
              reject(err)
            })
        } else {
          resolve({ locale: locale })
        }
      })
    },
    checkLangStorage() {
      return new Promise(resolve => {
        if (this.lbl == null) {
          let interval = setInterval(() => {
            if (this.lbl != null && this.lbl != undefined) {
              clearInterval(interval)
              return resolve(true)
            } else {
              this.getLbl()
            }
          }, 1000)
        } else {
          return resolve(true)
        }
      })
    },
    dateFullFormat(timestamp) {
      return Datetime.dateFullFormat(timestamp)
    },
    dateShortFormat(timestamp) {
      return Datetime.dateShortFormat(timestamp)
    },
    dateFullFormatUTC(timestamp) {
      return Datetime.dateFullFormatUTC(timestamp)
    },
    dateShortFormatUTC(timestamp) {
      return Datetime.dateShortFormatUTC(timestamp)
    },
    datetimeFullFormatUTC(timestamp) {
      return Datetime.datetimeFullFormatUTC(timestamp)
    },
    datetimeShortFormatUTC(timestamp) {
      return Datetime.datetimeShortFormatUTC(timestamp)
    },
    timeFormatUTC(timestamp) {
      return Datetime.timeFormatUTC(timestamp)
    },
    handleLoading(value) {
      this.$store.commit('Loading', value)
    },
    handleDashboard(value) {
      this.$store.commit('Dashboard', value)
    },
    handleFooter(value) {
      this.$store.commit('FooterMenu', value)
    },
    errorResponse(objerror, isPopup, router) {
      var error_message =
        this.lbl['Error occurred while processing your request.']
      console.log('Error : ', objerror.response)
      if (_.has(objerror, 'response.data.error')) {
        if (
          objerror.response.data.error !== null &&
          objerror.response.data.code != 999
        ) {
          error_message = objerror.response.data.error.message
        }
      }
      if (typeof objerror === 'string') {
        error_message = objerror
      }
      if (isPopup) {
        this.handleLoading(false)
        if (router) {
          this.$warning({
            title: this.lbl['alert-box-error-header'],
            content: error_message,
            centered: true,
            autoFocusButton: null,
            okText: this.lbl['user-permissions-ok'],
            onOk: () => {
              this.$router.push(router)
              this.handleLoading(true)
            },
          })
        } else {
          this.$warning({
            title: this.lbl['alert-box-error-header'],
            content: error_message,
            centered: true,
            autoFocusButton: null,
            okText: this.lbl['user-permissions-ok'],
            onOk: () => {},
          })
        }
      } else {
        this.$message.error(error_message)
      }
      return error_message
    },
    compareCurrentDate(date) {
      if (date) {
        let format = moment(date).format('DD-MM-YYYY')
        let format_now = moment(new Date()).format('DD-MM-YYYY')
        if (format == format_now) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    googleTagManager(page, category, name, label, action) {
      window.dataLayer1.push({
        page: page ? page : null,
        category: category ? category : null,
        name: name ? name : null,
        labels: label ? label : null,
        action: action ? action : null,
      })
      window.dataLayer2.push({
        page: page ? page : null,
        category: category ? category : null,
        name: name ? name : null,
        labels: label ? label : null,
        action: action ? action : null,
      })
    },
    handleDomain() {
      return new Promise(resolve => {
        const baseUrl = Vue.bzbsConfig.businessRule.url.sso
        if (
          Vue.bzbsConfig.businessRule.url.merge_net ||
          Vue.bzbsConfig.businessRule.url.merge ||
          Vue.bzbsConfig.businessRule.url.sso_net
        ) {
          if (
            window.location.origin ==
              Vue.bzbsConfig.businessRule.url.merge_net ||
            window.location.origin == Vue.bzbsConfig.businessRule.url.merge ||
            window.location.origin == Vue.bzbsConfig.businessRule.url.sso_net
          ) {
            window.location =
              baseUrl + window.location.pathname + window.location.search
            resolve(false)
          } else resolve(true)
        } else resolve(true)
      })
    },
  },
}
