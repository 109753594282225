<template>
  <div class="footer" :class="{ showing: !isShow }">
    <div class="container-fluid mx-0 px-0">
      <div class="row">
        <div class="col-sm-6 col-7 mx-0 px-0 footer-left-container">
          <router-link
            id="footer_privacy_link"
            :to="{
              name: 'about',
              params: {
                mode: 'privacy',
              },
            }"
            >{{ lbl['footer-menu-privacy'] }}
          </router-link>
          <router-link
            id="footer_contact_us_link"
            :to="{
              name: 'about',
              params: {
                mode: 'contactus',
              },
            }"
            >{{ lbl['footer-menu-help'] }}
          </router-link>
          <!-- <a href="/Privacy/Index">
            {{ lbl['footer-menu-privacy'] }}
          </a>
          <a href="/ContactUs/Index">
            {{ lbl['footer-menu-help'] }}
          </a> -->
        </div>
        <div class="col-sm-6 col-5 mx-0 px-0 footer-right-container">
          <img class="logo-new" alt="Footer logo" :src="logo" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from '@/mixin/Mixin'
export default {
  name: 'Footer',
  mixins: [Mixin],
  props: {
    isShow: Boolean,
  },
  data: function () {
    return {
      logo: require('@/assets/images/bzbs-logo-new.png'),
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss">
.footer {
  position: fixed !important;
  margin: 0 0;
  padding: 0 30px;
  bottom: 0;
  width: 100%;
  height: 40px !important;
  line-height: 40px !important;
  background-color: #f5f5f5;
  font-size: 90%;
  z-index: 99;
  &.showing {
    display: none;
  }
  a {
    text-decoration: none;
    color: black;
    margin: 0 10px;
  }
}
.footer-left-container {
  text-align: left !important;
}

.footer-right-container {
  text-align: right !important;
}
.logo-new {
  width: 85px;
  height: 18px;
}

@media (min-width: 576px) {
  .footer a:first-child {
    margin-left: 0px;
  }
}

@media (max-width: 576px) {
  .footer-left-container,
  .footer-right-container {
    text-align: center !important;
  }
}

@media only screen and (max-device-width: 768px) and (orientation: portrait) {
  .footer {
    padding: 0 20px;
  }
}

//แนวตั้ง mobile
@media only screen and (max-device-width: 360px) and (orientation: portrait) {
  .footer {
    padding: 0 20px !important;
    font-size: 84% !important;
  }
}
</style>
