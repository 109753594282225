<template>
  <div id="app" :class="[{ iphone: isIphone }, Browser, currentLocale]">
    <router-view />
    <Footer :is-show="$store.state.isFooterMenu" />
    <Loading id="appLoading" :is-show="$store.state.isAppLoad" />
  </div>
</template>

<script>
import Util from '@/helper/Utility.js'
import Mixin from '@/mixin/Mixin'
import Loading from '@/components/Layout/Loading'
import Footer from '@/components/Layout/Footer'
import Locale from '@/helper/locale.js'

export default {
  components: {
    Loading,
    Footer,
  },
  mixins: [Mixin],
  data: function () {
    return {
      isIphone: Util.getiPhone(),
      Browser: Util.getBrowserName(),
      isShow: false,
      currentLocale: Locale.getLocaleShort(),
    }
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';
#app {
  position: relative;
  > .container-fluid {
    min-height: 100vh;
    padding-bottom: 60px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    background-color: #f5f5f5;
    margin-bottom: 80px;
    max-width: 100%;
    padding-top: 102px;
    &.newbg {
      background-image: url('assets/images/bg_container.webp');
    }
  }

  .container-login {
    min-height: 100vh;
    background-image: url('assets/images/bg_container.webp') !important;
    background-color: rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
  }

  @media only screen and (max-device-width: 812px) and (orientation: landscape) {
    .container-login {
      height: 150vh;
    }
  }
}
</style>
