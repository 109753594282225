import Util from '@/helper/Utility.js';
import DatetimeHelper from '@/helper/DatetimeHelper.js';

export default {
    install (Vue) {
        Vue.filter('number_format', function(value) {
            var num = parseInt(value, 10);
            return Util.formatNum(num, 0)
        });
        Vue.filter('phone_format', function(value) {
            if (value == null) {
                return '';
            }
            var str = Util.contactNumberFormat(value, '-');
            return str;
        });
        Vue.filter('date_format', function(timestamp) {
            // Format : DD MMMM YYYY
            return DatetimeHelper.dateFullFormat(timestamp);
        });
        Vue.filter('time_format', function(timestamp) {
            // Format : hh:mm:ss
            return DatetimeHelper.timeFormat(timestamp);
        });
    }
};
