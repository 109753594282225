import Vue from 'vue'
import Router from 'vue-router'
import Account from './helper/AccountHelper'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/Account/Login',
      name: 'Login',
      component: () => import('@/module/Account/Login.vue'),
    },
    {
      path: '/Account/ForgotPassword',
      name: 'ForgotPassword',
      component: () => import('@/module/Account/ForgotPassword.vue'),
    },
    {
      path: '/Account/ResetPassword',
      name: 'ResetPassword',
      component: () => import('@/module/Account/ResetPassword.vue'),
    },
    {
      path: '/Account/RegisterVue',
      name: 'RegisterVue',
      component: () => import('@/module/Account/RegisterVue.vue'),
    },
    {
      path: '/Account/Package',
      name: 'Package',
      component: () => import('@/module/Account/SelectPackage/Package.vue'),
    },
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/module/Home/Dashboard.vue'),
    },
    {
      path: '/Dashboard',
      name: 'Dashboard2',
      component: () => import('@/module/Home/DashboardV2.vue'),
    },
    {
      path: '/Dashboard/BranchDetail',
      name: 'BranchDetail',
      component: () => import('@/module/Home/BranchDetail.vue'),
    },
    {
      path: '/Dashboard/RewardRank',
      name: 'RewardRank',
      component: () => import('@/module/Home/RewardRank.vue'),
    },
    {
      path: '/SmartNotify',
      name: 'SmartNotify',
      component: () => import('@/module/SmartNotify/SmartMain.vue'),
    },
    {
      path: '/SmartNotifyCriteria',
      name: 'SmartNotifyCriteria',
      component: () => import('@/module/SmartNotify/SmartMainCriteria.vue'),
    },
    {
      path: '/SmartNotify/InactiveUser',
      name: 'InactiveUser',
      component: () => import('@/module/SmartNotify/InactiveUser.vue'),
    },
    {
      path: '/SmartNotify/InactiveUserV2',
      name: 'InactiveUserV2',
      component: () => import('@/module/SmartNotify/InactiveUserV2.vue'),
    },
    {
      path: '/Import/AdjustPoint',
      name: 'AdjustPoint',
      component: () => import('@/module/ImportBatchPoint/AdjustPoint.vue'),
    },
    {
      path: '/Import/AdjustPoint/:data',
      name: 'AdjustPoint',
      component: () => import('@/module/ImportBatchPoint/AdjustPoint.vue'),
    },
    {
      path: '/Import/LogPointImport',
      name: 'LogPoint',
      component: () => import('@/module/ImportBatchPoint/PointLog.vue'),
    },
    {
      path: '/Import/AdjustSpending',
      name: 'AdjustSpending',
      component: () => import('@/module/ImportBatchPoint/AdjustSpending.vue'),
    },
    {
      path: '/Import/AdjustSpending/:data',
      name: 'AdjustSpending',
      component: () => import('@/module/ImportBatchPoint/AdjustSpending.vue'),
    },
    {
      path: '/Import/LogSpendingImport',
      name: 'SpendingLog',
      component: () => import('@/module/ImportBatchPoint/SpendingLog.vue'),
    },
    {
      path: '/Import/ImportUser',
      name: 'ImportUser',
      component: () => import('@/module/ImportUser/ImportUserNew.vue'),
    },
    {
      path: '/Import/ImportUser/:data',
      name: 'ImportUser',
      component: () => import('@/module/ImportUser/ImportUserNew.vue'),
    },
    {
      path: '/Import/ImportUserLog',
      name: 'ImportUserLog',
      component: () => import('@/module/ImportUser/ImportUserLogNew.vue'),
    },
    {
      path: '/Import/ImportUserSearch',
      name: 'ImportUserSearch',
      component: () => import('@/module/ImportUser/ImportUserSearchNew.vue'),
    },
    {
      path: '/UserProfile/UserCustomerManage',
      name: 'UserCustomerManage',
      component: () => import('@/module/UserProfile/UserCustomerManageNew.vue'),
    },
    {
      path: '/UserProfile/UserCustomerLog',
      name: 'UserCustomerLog',
      component: () => import('@/module/UserProfile/UserCustomerLogNew.vue'),
    },
    {
      path: '/CreateUserLevel',
      name: 'CreateUserLevel',
      component: () => import('@/module/UserProfile/CreateUserLevel.vue'),
    },
    {
      path: '/StepOne',
      name: 'StepOne',
      component: () => import('@/module/UserProfile/StepOne.vue'),
    },
    {
      path: '/StepTwo',
      name: 'StepTwo',
      component: () => import('@/module/UserProfile/StepTwo.vue'),
    },
    {
      path: '/StepThree',
      name: 'StepThree',
      component: () => import('@/module/UserProfile/StepThree.vue'),
    },
    {
      path: '/StepFour',
      name: 'StepFour',
      component: () => import('@/module/UserProfile/StepFour.vue'),
    },
    {
      path: '/PreviewLevel',
      name: 'PreviewLevel',
      component: () => import('@/module/UserProfile/PreviewLevel.vue'),
    },
    {
      path: '/HistoryLevel',
      name: 'HistoryLevel',
      component: () => import('@/module/UserProfile/History.vue'),
    },
    {
      path: '/Settings/Line/ConnectLine',
      name: 'ConnectLine',
      component: () => import('@/module/Settings/Line/ConnectLine.vue'),
    },
    {
      path: '/Settings/Line/RichMenu',
      name: 'RichMenu',
      component: () => import('@/module/Settings/Line/RichMenu.vue'),
    },
    {
      path: '/Settings/MemberPrivacy/PdpaUpload',
      name: 'PdpaUpload',
      component: () =>
        import('@/module/Settings/MemberPrivacy/PdpaUploadNew.vue'),
    },
    {
      path: '/Settings/MemberPrivacy/PdpaUploadHistory',
      name: 'PdpaUploadHistory',
      component: () =>
        import('@/module/Settings/MemberPrivacy/PdpaUploadHistoryNew.vue'),
    },
    {
      path: '/PlanReward/BurnList',
      name: 'BurnList',
      component: () => import('@/module/PlanReward/BurnListNew.vue'),
    },
    {
      path: '/PlanReward/EarnList',
      name: 'EarnList',
      component: () => import('@/module/PlanReward/EarnList.vue'),
    },
    {
      path: '/PlanReward/StampList',
      name: 'StampList',
      component: () => import('@/module/PlanReward/StampList.vue'),
    },
    {
      path: '/Settings/Link/LinkSetup',
      name: 'LinkSetup',
      component: () => import('@/module/Settings/Link/LinkSetup.vue'),
    },
    {
      path: '/UserPermission',
      name: 'UserPermission',
      component: () => import('@/module/UserPermission/UserPermission.vue'),
    },
    {
      path: '/RewardList',
      name: 'RewardList',
      component: () => import('@/module/RewardList/RewardList.vue'),
    },
    {
      path: '/Extensions',
      name: 'Extensions',
      component: () => import('@/module/Settings/3rdParty/pages/Index.vue'),
    },
    {
      path: '/FriendGetFriends',
      name: 'FriendGetFriends',
      component: () => import('@/module/FriendGetFriends/Main.vue'),
    },
    {
      path: '/FriendGetFriends/:mode/:id',
      name: 'FriendGetFriendsCU',
      component: () => import('@/module/FriendGetFriends/CUFriend.vue'),
    },
    {
      path: '/FriendGetFriends/History',
      name: 'FriendGetFriendsHistory',
      component: () => import('@/module/FriendGetFriends/History.vue'),
    },
    {
      path: '/Settings/Theme',
      name: 'Theme',
      component: () => import('@/module/Settings/Theme/pages/Index.vue'),
    },
    {
      path: '/Settings/AccountSetting',
      name: 'AccountSetting',
      component: () =>
        import('@/module/Settings/AccountSetup/pages/Account.vue'),
    },
    {
      path: '/Setting/AccountSetting',
      name: 'AccountSettingBeeBenefit',
      component: () =>
        import('@/module/Settings/AccountSetup/pages/AccountBeeBenefit.vue'),
    },
    {
      path: '/Settings/SelfOnBoard/AccountSetting',
      name: 'AccountSetting2',
      component: () =>
        import('@/module/Settings/AccountSetup/SelfOnBoard/Account.vue'),
    },
    {
      path: '/Settings/BranchSetting',
      name: 'BranchSetting',
      component: () =>
        import('@/module/Settings/AccountSetup/pages/Branch.vue'),
    },
    {
      path: '/Settings/ConnectFacebook',
      name: 'ConnectFacebook',
      component: () =>
        import('@/module/Settings/AccountSetup/pages/ConnectFacebook.vue'),
    },
    {
      path: '/Settings/ConnectSetting',
      name: 'ConnectSetting',
      component: () =>
        import('@/module/Settings/AccountSetup/pages/Connect.vue'),
    },
    {
      path: '/Settings/SelfOnBoard/ConnectSetting',
      name: 'ConnectSetting2',
      component: () =>
        import('@/module/Settings/AccountSetup/SelfOnBoard/Connect.vue'),
    },
    {
      path: '/about/:mode',
      name: 'about',
      component: () => import('@/module/ContactusPrivacy/main.vue'),
    },
    {
      path: '/configprofile',
      name: 'configprofile',
      component: () => import('@/module/configProfile/configProfile.vue'),
    },
    {
      path: '/usermanual',
      name: 'usermanual',
      component: () => import('@/module/UserManul/main.vue'),
    },
    {
      path: '/tutorial',
      name: 'tutorial',
      component: () => import('@/module/Tutorial/pages/main.vue'),
    },
    {
      path: '/View/Package/:package',
      name: 'ViewPackage',
      component: () => import('@/module/configProfile/view/Package.vue'),
    },
    {
      path: '/Account/Setup',
      name: 'AccountSetup',
      component: () => import('@/module/Account/Setup/pages/Index.vue'),
    },
    {
      path: '/UserPermission/PermissionType',
      name: 'PermissionType',
      component: () => import('@/module/UserPermission/PermissionType.vue'),
    },
    {
      path: '/PageNotFound/ErrorConfig',
      name: 'ErrorConfig',
      component: () => import('@/module/PageNotFound/pages/configAzure.vue'),
    },
    {
      path: '/Announcement',
      name: 'Announcement',
      component: () => import('@/module/Announcement/pages/Main.vue'),
    },
    {
      path: '/Announcement/Detail/:partitionKey/:rowKey',
      name: 'AnnouncementDetail',
      component: () => import('@/module/Announcement/pages/Detail.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/module/PageNotFound/pages/404.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name == 'Dashboard' || to.name == 'Dashboard2') {
    var Pageconfig = Vue.$cookies.get('ProfileConfig')
    if (!Vue.$cookies.get('CRMUser')) {
      next({ name: 'Login' })
    } else {
      if (!Pageconfig) {
        Account.apiProfileConfig().then(res => {
          console.log(res)
          if (res) {
            if (to.name == 'Dashboard') {
              if (res.data.ShowNewDashboard) {
                next({ name: 'Dashboard2' })
              } else {
                next()
              }
            } else {
              if (to.name == 'Dashboard2') {
                if (!res.data.ShowNewDashboard) {
                  next({ name: 'Dashboard' })
                } else {
                  next()
                }
              } else {
                next()
              }
            }
          } else {
            next()
          }
        })
      } else {
        if (to.name == 'Dashboard') {
          if (Pageconfig.ShowNewDashboard) {
            next({ name: 'Dashboard2' })
          } else {
            next()
          }
        } else {
          if (to.name == 'Dashboard2') {
            if (!Pageconfig.ShowNewDashboard) {
              next({ name: 'Dashboard' })
            } else {
              next()
            }
          } else {
            next()
          }
        }
      }
    }
  } else {
    next()
  }
})

export default router
