import moment from 'moment'
export default {
  /****** String helper *******
   * @function isEmptyStr
   * @function newLineToBr
   * @function formatNum
   * @function removeEmojis
   * @function contactNumberFormat
   ****************************/
  isEmptyStr: function (str) {
    return !str || str == ''
  },
  newLineToBr: function (str) {
    if (!str) return ''
    return str.replace(/\n/g, '<br />').trim()
  },
  formatNum: function (n, d) {
    var str = (n || 0).toFixed(d)
    var x = str.split('.')
    var x1 = x[0]
    var x2 = x.length > 1 ? '.' + x[1] : ''
    var rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    return x1 + x2
  },
  removeEmojis: function (string) {
    var regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    return string.replace(regex, '')
  },
  contactNumberFormat: function (strContactNumber, delimeter) {
    if (strContactNumber.length < 10) {
      return strContactNumber
    }

    var n1 = strContactNumber.substr(0, 3)
    var n2 = strContactNumber.substr(3, 3)
    var n3 = strContactNumber.substr(6)

    return `${n1}${delimeter}${n2}${delimeter}${n3}`
  },

  /******* Bzbs utility ********
   * @function shuffleToken
   * @function reShuffleToken
   * @function getBrowserVersion
   * @function getOSName
   * @function normalObject
   * @function stringFormat
   * @function cleanObjectNullKey
   * @function cleanObjectEmptyStr
   * @function isEmptyObject
   * @function log
   * @function logJson
   * @function scrollTop
   *****************************/
  shuffleToken: function (bzbtoken) {
    return (
      bzbtoken.substring(bzbtoken.length - 5, bzbtoken.length) +
      bzbtoken.substring(1, bzbtoken.length - 5)
    )
  },
  reShuffleToken: function (token) {
    let head = token.substr(0, 5)
    let tail = token.substr(5)
    let newToken = '.' + tail + head
    return newToken
  },
  mapLoginTypeId(type) {
    if (type) {
      var loginType = type.toLowerCase()
      switch (loginType.replace(/\s/g, '')) {
        case 'line,device':
          return 2
        case 'line':
          return 1
        case 'email,facebook':
          return 5
        case 'email':
          return 3
        case 'device':
          return 4
        case 'facebook':
          return 5
        default:
          return 1
      }
    }
  },
  getBrowserVersion: function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) return 'Opera ' + tem[1]
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return M.join(' ')
  },
  getBrowserName: function () {
    let browserVersion = this.getBrowserVersion().toLowerCase()
    if (browserVersion.includes('safari')) {
      return 'safari'
    }
    if (browserVersion.includes('chrome')) {
      return 'chrome'
    }
    if (browserVersion.includes('opera')) {
      return 'opera'
    }
    if (browserVersion.includes('firefox')) {
      return 'firefox'
    }
    if (browserVersion.includes('msie')) {
      return 'msie'
    }
    if (browserVersion.includes('trident')) {
      return 'trident'
    }
  },
  getOSName: function () {
    var OSName = 'Unknown OS'
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows'
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS'
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX'
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux'

    return OSName
  },
  getiPhone: function () {
    return [
      // 'iPad Simulator',
      'iPhone Simulator',
      // 'iPod Simulator',
      // 'iPad',
      'iPhone',
      // 'iPod',
    ].includes(navigator.platform)
  },
  normalObject: function (obj) {
    if (!obj) {
      return null
    }
    var json = JSON.stringify(obj)
    var normalObj = JSON.parse(json)
    return normalObj
  },
  cleanObjectNullKey: function (object) {
    var cleanObject = {}
    for (var i in object) {
      if (object[i] != null) {
        cleanObject[i] = object[i]
      }
    }
    return cleanObject
  },
  cleanObjectEmptyStr: function (object) {
    var cleanObject = {}
    for (var i in object) {
      if (!this.isEmptyStr(object[i])) {
        cleanObject[i] = object[i]
      }
    }
    return cleanObject
  },
  stringFormat: function () {
    var s = arguments[0]
    for (var i = 0; i < arguments.length - 1; i += 1) {
      var reg = new RegExp('\\{' + i + '\\}', 'gm')
      s = s.replace(reg, arguments[i + 1])
    }
    return s
  },
  isEmptyObject: function (obj) {
    if (obj == null || obj == '') {
      return true
    }

    var str = JSON.stringify(obj)

    if (str == '{}' || str == '[]') {
      return true
    }

    return false
  },
  log: function (text1, text2) {
    if (text2) {
      console.log(text1, this.normalObject(text2))
    } else {
      if (text1 == null) {
        console.log(text1)
        return
      }
      console.log(this.normalObject(text1))
    }
  },

  /**
   * @param {Object|Array} obj
   * @param {String} color red green blue purple
   */
  logJson: function (obj, color) {
    if (obj == null) {
      console.log(obj)
      return
    }
    var json = JSON.stringify(obj, null, 2)
    var colorCode = null
    switch (color) {
      case 'red':
        colorCode = '\x1b[31m%s\x1b[0m'
        break
      case 'green':
        colorCode = '\x1b[32m%s\x1b[0m'
        break
      case 'blue':
        colorCode = '\x1b[34m%s\x1b[0m'
        break
      case 'purple':
        colorCode = '\x1b[35m%s\x1b[0m'
        break
      default:
        colorCode = null
    }

    if (colorCode != null) {
      console.log(colorCode, json)
    } else {
      console.log(json)
    }
  },
  scrollTop: function (smoot) {
    var config = { top: 0 }
    if (smoot) {
      config.behavior = 'smooth'
    }
    window.scrollTo(config)
  },
  ranges: {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('week'), moment()],
    'Last 7 Days': [moment().subtract(7, 'days'), moment()],
    'Last 30 Days': [moment().subtract(30, 'days'), moment()],
    'Last 3 Months': [moment().subtract(3, 'month'), moment()],
    'Last 6 Months': [moment().subtract(6, 'month'), moment()],
    'Last Year': [moment().subtract(1, 'year'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  },

  /******** Validate **********
   * @function validateZipCode
   * @function validateEmail
   ****************************/
  validateZipCode: function (strZipCode) {
    if (this.isEmptyStr(strZipCode)) {
      return false
    }
    return strZipCode.match(/^[0-9]{5}/)
  },
  validateEmail: function (strEmail) {
    // eslint-disable-next-line
    var rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return rex.test(strEmail)
  },
  vMinMaxLengthPassword: function (data) {
    return data.length <= 20 && data.length >= 6
  },
  vLowLetterAZ: function (data) {
    let format = /([a-z])/
    if (data == '') return false
    return format.test(data)
  },
  vUpLetterAZ: function (data) {
    let format = /([A-Z])/
    if (data == '') return false
    return format.test(data)
  },
  vContainNumber: function (data) {
    return data.split('').some(i => !isNaN(i))
  },
  vContainNumberOrLetter: function (data) {
    let format = /([a-z])/
    if (data == '') return false
    return data.split('').some(i => !isNaN(i)) || format.test(data)
  },
  vSymbols: function (data) {
    let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
    return format.test(data)
  },
  vThLetter: function (data) {
    let format = /([ก-๙])/
    return !format.test(data)
  },
}
