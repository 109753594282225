import Util from '@/helper/Utility.js'

/**
 * @exports ProfileModel
 * @exports ProfileParamsBuilder
 * @exports ProfileAddressModel
 * @exports AddressParamsBuilder
 * @exports ProfileShippingAddressModel
 * @exports ShippingAddressParamsBuilder
 */

export function ProfileModel(objUserBzbsProfile) {
  this.strUserId = objUserBzbsProfile.UserId || ''
  this.strName = objUserBzbsProfile.Name || ''
  this.strFirstName = objUserBzbsProfile.FirstName || ''
  this.strLastName = objUserBzbsProfile.LastName || ''

  this.strGender = objUserBzbsProfile.Gender || ''
  this.strBirthDate = objUserBzbsProfile.BirthDate || ''
  this.strAge = objUserBzbsProfile.Age || ''
  this.strContactNumber = objUserBzbsProfile.Contact_Number || ''
  this.strEmail = objUserBzbsProfile.Email || ''
  this.strNationalIdCard = objUserBzbsProfile.NationalIdCard || ''
  this.strLocale = objUserBzbsProfile.Locale || ''

  this.isNotificationEnable = objUserBzbsProfile.NotificationEnable || ''
  this.strPostToFacebook = objUserBzbsProfile.PostToFacebook || ''
  this.strModifyDate = objUserBzbsProfile.ModifyDate || ''
  this.strCreditCardHolder = objUserBzbsProfile.CreditCardHolder || ''
  this.strCreditCardNo = objUserBzbsProfile.CreditCardNo || ''
  this.strCreditCardType = objUserBzbsProfile.CreditCardType || ''
  this.strCreditCardExpireMonth = objUserBzbsProfile.CreditCardExpireMonth || ''
  this.strCreditCardExpireYear = objUserBzbsProfile.CreditCardExpireYear || ''
  this.strPassport = objUserBzbsProfile.Passport || ''
  this.strMembershipId = objUserBzbsProfile.MembershipId || ''
  this.strMembershipUserName = objUserBzbsProfile.MembershipUserName || ''
  this.strLoginType = objUserBzbsProfile.LoginType || ''

  // Custom fields
  this.strDisplayName = objUserBzbsProfile.DisplayName
  this.strPlayboyCampaignId = objUserBzbsProfile.PlayboyCampaignId
  this.strUserType = objUserBzbsProfile.UserType
  this.strUserTypeName = objUserBzbsProfile.UserTypeName
  this.strOtherUserId = objUserBzbsProfile.OtherUserId
  this.strCategoryConfig = objUserBzbsProfile.CategoryConfig

  this.strPhonePurchase = objUserBzbsProfile.PhonePurchase || ''
  this.strIncome = objUserBzbsProfile.Income || ''
  this.strInterests = objUserBzbsProfile.Interests || ''
  this.strRegion = objUserBzbsProfile.Region || ''
  this.strRefercode = objUserBzbsProfile.ReferCode || ''

  this.strMaritalStatus = objUserBzbsProfile.MaritalStatus || ''
  this.strNationality = objUserBzbsProfile.Nationality || ''
  this.strReligion = objUserBzbsProfile.Religion || ''
  this.strHighestEducation = objUserBzbsProfile.HighestEducation || ''
  this.strOccupation = objUserBzbsProfile.Occupation || ''
  this.strDriverLicenseNumber = objUserBzbsProfile.DriverLicenseNumber || ''

  this.strLatitude = objUserBzbsProfile.Latitude || ''
  this.strLongitude = objUserBzbsProfile.Longitude || ''
  this.strJoinDate = objUserBzbsProfile.JoinDate || ''

  this.strIsConnectLine = objUserBzbsProfile.LineLoginChannelId ? true : false

  this.updated_points = objUserBzbsProfile.updated_points || ''
  if (objUserBzbsProfile.updated_points_other)
    this.updated_points_other = objUserBzbsProfile.updated_points_other || ''

  this.isAllowTermAndCondition = objUserBzbsProfile.TermAndCondition || null
  this.isAllowDataPrivacy = objUserBzbsProfile.DataPrivacy || null

  this.buzzebees = objUserBzbsProfile.buzzebees || ''
  this.terminate = objUserBzbsProfile.terminate
    ? objUserBzbsProfile.terminate
    : false

  this.getParams = function () {
    var params = {
      firstname: this.strFirstName,
      lastname: this.strLastName,
      gender: this.strGender,
      birthdate: this.strBirthDate,
      contact_Number: this.strContantNumber,
      email: this.strEmail,
      idcard: this.strNationalIdCard,
      locale: this.strLocale,
      notification: this.isNotificationEnable,
      income: this.strIncome,
      interests: this.strInterests,
      region: this.strReligion,
      maritalstatus: this.strMaritalStatus,
      nationality: this.strNationality,
      highesteducation: this.strHighestEducation,
      occupation: this.strOccupation,
      passport: this.strPassport,
      driverlicense: this.strDriverLicenseNumber,
      displayname: this.strDriverLicenseNumber,
    }

    return Util.cleanObjectEmptyStr(params)
  }
}

export function ProfileParamsBuilder() {
  this.getParams = function (profile) {
    if (!profile) return
    var params = {
      firstname: profile.strFirstName,
      lastname: profile.strLastName,
      gender: profile.strGender,
      birthdate: profile.strBirthDate,
      contact_Number: profile.strContactNumber,
      email: profile.strEmail,
      idcard: profile.strNationalIdCard,
      locale: profile.strLocale,
      notification: profile.isNotificationEnable,
      income: profile.strIncome,
      interests: profile.strInterests,
      region: profile.strReligion,
      maritalstatus: profile.strMaritalStatus,
      nationality: profile.strNationality,
      highesteducation: profile.strHighestEducation,
      occupation: profile.strOccupation,
      passport: profile.strPassport,
      driverlicense: profile.strDriverLicenseNumber,
      displayname: profile.strDriverLicenseNumber,
    }

    return Util.cleanObjectEmptyStr(params)
  }
}

export function ProfileAddressModel(objUserBzbsProfile) {
  if (objUserBzbsProfile) {
    this.strLineLoginChannelId = objUserBzbsProfile.LineLoginChannelId || ''
    this.strLoginType = objUserBzbsProfile.LoginType || ''
    this.strBusinessName = objUserBzbsProfile.BusinessName || ''
    this.strNumber = objUserBzbsProfile.Number || ''
    this.strMoo = objUserBzbsProfile.Moo || ''
    this.strVillage = objUserBzbsProfile.Village || ''
    this.strRoad = objUserBzbsProfile.Road || ''
    this.strSoi = objUserBzbsProfile.Soi || ''
    this.strBuilding = objUserBzbsProfile.Building || ''
    this.strRoom = objUserBzbsProfile.Room || ''
    this.strFloor = objUserBzbsProfile.Floor || ''
    this.strAddress = objUserBzbsProfile.Address || ''
    this.strFullAddress = objUserBzbsProfile.FullAddress || ''
    this.strZipcode = objUserBzbsProfile.Zipcode || ''
    this.strCountryCode = objUserBzbsProfile.CountryCode || ''
    this.strProvinceCode = objUserBzbsProfile.ProvinceCode || ''
    this.strDistrictCode = objUserBzbsProfile.DistrictCode || ''
    this.strSubDistrictCode = objUserBzbsProfile.SubDistrictCode || ''
    this.strCountryName = objUserBzbsProfile.CountryName || ''
    this.strProvinceName = objUserBzbsProfile.ProvinceName || ''
    this.strDistrictName = objUserBzbsProfile.DistrictName || ''
    this.strSubDistrictName = objUserBzbsProfile.SubDistrictName || ''
  } else {
    this.strNumber = ''
    this.strMoo = ''
    this.strVillage = ''
    this.strRoad = ''
    this.strSoi = ''
    this.strBuilding = ''
    this.strRoom = ''
    this.strFloor = ''
    this.strAddress = ''
    this.strFullAddress = ''
    this.strZipcode = ''
    this.strCountryCode = ''
    this.strProvinceCode = ''
    this.strDistrictCode = ''
    this.strSubDistrictCode = ''
    this.strCountryName = ''
    this.strProvinceName = ''
    this.strDistrictName = ''
    this.strSubDistrictName = ''
  }
}

export function AddressParamsBuilder() {
  this.getParams = function (addr) {
    if (!addr) return
    var params = {
      address: addr.strAddress,
      village: addr.strVillage,
      building: addr.strBuilding,
      number: addr.strNumber,
      moo: addr.strMoo,
      room: addr.strRoom,
      floor: addr.strFloor,
      soi: addr.strSoi,
      road: addr.strRoad,
      country_code: addr.strCountryCode,
      country_name: addr.strCountryName,
      province_code: addr.strProvinceCode,
      province_name: addr.strProvinceName,
      district_code: addr.strDistrictCode,
      district_name: addr.strDistrictName,
      subdistrict_code: addr.strSubDistrictCode,
      subdistrict_name: addr.strSubDistrictName,
      zipcode: addr.strZipcode,
    }

    return Util.cleanObjectEmptyStr(params)
  }
}

export function ProfileShippingAddressModel(objUserBzbsProfile) {
  if (objUserBzbsProfile) {
    if (objUserBzbsProfile.ShippingZipcode) {
      //Initial with profile shipping addres.
      this.strFirstName = objUserBzbsProfile.ShippingFirstName || ''
      this.strLastName = objUserBzbsProfile.ShippingLastName || ''
      this.strContactNumber = objUserBzbsProfile.ShippingContactNumber || ''
      this.strEmail = objUserBzbsProfile.ShippingEmail || ''
      this.strAddress = objUserBzbsProfile.ShippingAddress || ''
      this.strZipcode = objUserBzbsProfile.ShippingZipcode || ''
      this.strProvinceCode = objUserBzbsProfile.ShippingProvinceCode || null
      this.strDistrictCode = objUserBzbsProfile.ShippingDistrictCode || null
      this.strSubDistrictCode =
        objUserBzbsProfile.ShippingSubDistrictCode || null
      this.strProvinceName = objUserBzbsProfile.ShippingProvinceName || ''
      this.strDistrictName = objUserBzbsProfile.ShippingDistrictName || ''
      this.strSubDistrictName = objUserBzbsProfile.ShippingSubDistrictName || ''
    } else {
      //Initial with profile address.
      this.strFirstName = objUserBzbsProfile.FirstName || ''
      this.strLastName = objUserBzbsProfile.LastName || ''
      this.strContactNumber = objUserBzbsProfile.Contact_Number || ''
      this.strEmail = objUserBzbsProfile.Email || ''
      this.strAddress = objUserBzbsProfile.Address || ''
      this.strZipcode = objUserBzbsProfile.Zipcode || ''
      this.strProvinceCode = objUserBzbsProfile.ProvinceCode || null
      this.strDistrictCode = objUserBzbsProfile.DistrictCode || null
      this.strSubDistrictCode = objUserBzbsProfile.SubDistrictCode || null
      this.strProvinceName = objUserBzbsProfile.ProvinceName || ''
      this.strDistrictName = objUserBzbsProfile.DistrictName || ''
      this.strSubDistrictName = objUserBzbsProfile.SubDistrictName || ''
    }
  } else {
    //Initial default.
    this.strFirstName = ''
    this.strLastName = ''
    this.strContactNumber = ''
    this.strEmail = ''
    this.strAddress = ''
    this.strZipcode = ''
    this.strProvinceCode = null
    this.strDistrictCode = null
    this.strSubDistrictCode = null
    this.strProvinceName = ''
    this.strDistrictName = ''
    this.strSubDistrictName = ''
  }
}

export function ShippingAddressParamsBuilder() {
  /**
   * @param {ProfileShippingAddressModel} addr // This params should be created by ProfileShippingAddressModel.
   */
  this.getParams = function (addr) {
    if (!addr) return {}
    var params = {
      shippingfirstname: addr.strFirstName,
      shippinglastname: addr.strLastName,
      shipping_contact_number: addr.strContactNumber,
      shipping_email: addr.strEmail,
      shipping_address: addr.strAddress,
      shipping_zipcode: addr.strZipcode,
      shipping_province_code: addr.strProvinceCode,
      shipping_province_name: addr.strProvinceName,
      shipping_district_code: addr.strDistrictCode,
      shipping_district_name: addr.strDistrictName,
      shipping_subdistrict_code: addr.strSubDistrictCode,
      shipping_subdistrict_name: addr.strSubDistrictName,
    }

    return Util.cleanObjectEmptyStr(params)
  }
}
