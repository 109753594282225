import Vue from 'vue';
import BzbsApi from '@/core/Http/BzbsApi.js';
import _ from 'lodash';

/**
 * @function getLocale
 * @function getLocaleCode
 * @function getLbl
 * @function getLocaleShort
 * @function setLocale
 */
export default {
	KEY_COOKIE_LOCALE: 'Locale',
	defaultLocale: 'th',
	getLocale() {
		var locale = Vue.$cookies.get(this.KEY_COOKIE_LOCALE);
		if (Vue.bzbsConfig.businessRule.webFor.PH) {
			if (locale == null) {
				this.defaultLocale = 'ph';
				this.setLocale(this.defaultLocale);
			}
		}
		if (locale == null) {
			locale = this.defaultLocale;
			this.setLocale(this.defaultLocale);
		}
		return locale;
	},
	getLocaleCode() {
		return this.getLocale();
	},
	getBlobLbl() {
		var urlblob = Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/localization.json';
		return new Promise((resolve) => {
			BzbsApi.connectBzbsGetBlob(urlblob).then((result) => {
				if (Vue.bzbsConfig.businessRule.webFor.PH) {
					let jsonLocale = {
						th: {},
						en: {},
						ph: {}
					};
					_.forEach(result.data, (item) => {
						jsonLocale['th'][item.id] = item.th;
						jsonLocale['en'][item.id] = item.ph_en;
						jsonLocale['ph'][item.id] = item.ph;
					});
					localStorage.setItem('jsonLocale', JSON.stringify(jsonLocale));
					resolve(true);
				} else {
					let jsonLocale = {
						th: {},
						en: {},
						ph: {}
					};
					_.forEach(result.data, (item) => {
						jsonLocale['th'][item.id] = item.th;
						jsonLocale['en'][item.id] = item.en;
						jsonLocale['ph'][item.id] = item.ph;
					});
					localStorage.setItem('jsonLocale', JSON.stringify(jsonLocale));
					resolve(true);
				}
			});
		});
	},
	getLbl() {
		return this.jsonLocale[this.getLocaleShort()];
	},
	getLocaleShort() {
		var locale = this.getLocale();
		return locale;
	},
	setLocale(localeCode) {
		Vue.$cookies.set(this.KEY_COOKIE_LOCALE, localeCode, null, null, null, true, "Strict");
	}
};
