import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
import {
  ProfileParamsBuilder,
  AddressParamsBuilder,
  ShippingAddressParamsBuilder,
} from '@/core/Account/model/AccountModel.js'

/**
 * @method updateLanguage
 * @method getProfile
 * @method getLoginTypeList
 * @method getPoint
 * @method updateProfile
 * @method updateShippingAddress
 * @method registerUser
 * @method changePassword
 */
export default {
  KEY_COOKIE_USER: 'CRMUser',

  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  agencyId: function () {
    return Cache.get('CRMUser').AgencyId
  },
  updateLanguage: function (locale) {
    var params = {
      locale: locale,
    }
    var strUrl = this.config().bzbsUrl + '/profile/me/locale'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },

  /**
   * Get user profile.
   * @param {object} strBzbsToken
   * @return {promises} Profile response.
   */
  getProfile: function (input) {
    var params = {
      agencyId: input.AgencyId,
      device_app_id: this.config().client_id,
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/profile'
    return BzbsApi.connectBzbsGet(strUrl, input.Token, params)
  },

  getLoginTypeList: function () {
    var strUrl = this.config().bzbsServiceUrl + '/config/crmplus_login_type_v2'
    return BzbsApi.connectBzbsGet(strUrl, null, null)
  },

  getProfileName: function () {
    var strUrl = this.config().bzbsUrl + '/profile/me'
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },

  getForgetPassword: function (email) {
    var params = {
      device_app_id: this.config().client_id,
    }
    var strUrl =
      this.config().bzbsUrl + '/profile/' + email + '/forget_password'
    return BzbsApi.connectBzbsGet(strUrl, null, params)
  },
  postForgetPassword: function (email) {
    var params = {
      device_app_id: this.config().client_id,
      userId: email,
      agencyId: this.agencyId(),
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusoffice/user_forget_password'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postresetPassword: function (strBzbsToken = null, data) {
    var params = {
      ...data,
    }
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/forget_password?device_app_id=' +
      this.config().client_id
    return BzbsApi.connectBzbsPost(strUrl, strBzbsToken, params)
  },

  /**
   * Get user profile.
   * @param {object} obj
   * @return {promises} Profile response.
   */
  getCRMPlusProfile: function () {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
        ? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
        : this.config().agency_id,
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/profile'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },

  /**
   * Get point.
   * @param {string} strBzbsToken
   * @return {promises} Point response.
   */
  getPoint: function (strBzbsToken) {
    var strUrl = this.config().bzbsUrl + '/profile/me/updated_points'
    return BzbsApi.connectBzbsGet(strUrl, strBzbsToken)
  },

  /**
   * Update user profile.
   * @param {string} strBzbsToken
   * @param {object} objProfileInfo
   * @param {object} objProfileAdress
   * @param {string} strExtendJson
   * @param {string} strUploadKey
   * @param {object} objUpload
   * @return {promises} user response.
   */
  updateProfile: function (
    strBzbsToken,
    profile,
    profileAddress,
    strExtendJson,
    strUploadKey,
    objUpload,
  ) {
    var profileParams = new ProfileParamsBuilder()
    var params = profileParams.getParams(profile)
    if (profileAddress) {
      var addressParams = new AddressParamsBuilder()
      var addr = addressParams.getParams(profileAddress)
      params = { ...params, ...addr }
    }

    if (strExtendJson) params.extendjsonstring = strExtendJson

    var strUrl = this.config().bzbsUrl + '/profile/me'
    return BzbsApi.connectBzbsPost(
      strUrl,
      strBzbsToken,
      params,
      strUploadKey,
      objUpload,
    )
  },

  /**
   * Update user shipping address.
   * @param {string} strBzbsToken
   * @param {object} objProfileShippingAddress
   * @return {promises} Profile shipping address.
   */
  updateShippingAddress: function (strBzbsToken, objProfileShippingAddress) {
    var paramsShipping = new ShippingAddressParamsBuilder()
    var params = paramsShipping.getParams(objProfileShippingAddress)
    var strUrl = this.config().bzbsUrl + '/profile/me/shipping'
    return BzbsApi.connectBzbsPost(strUrl, strBzbsToken, params)
  },

  registerUser: function (
    objRegisterModel,
    haveOTP,
    objRegisterOTPModel,
    objRegisterDeviceInfoModel,
    objRegisterAddressModel,
    objRegisterOtherInfoModel,
  ) {
    var params = {
      app_id: Vue.bzbsConfig.client_id,
    }

    if (!objRegisterModel) {
      return null
    }

    if (haveOTP && !objRegisterOTPModel) {
      return null
    }

    //Basic info.
    if (objRegisterModel.strUsername)
      params.username = objRegisterModel.strUsername

    if (objRegisterModel.strPassword)
      params.password = objRegisterModel.strPassword

    if (objRegisterModel.strConfirmPassword)
      params.confirmpassword = objRegisterModel.strConfirmPassword

    if (objRegisterModel.strFirstname)
      params.firstname = objRegisterModel.strFirstname

    if (objRegisterModel.strLastname)
      params.lastname = objRegisterModel.strLastname

    if (objRegisterModel.strEmail) params.email = objRegisterModel.strEmail

    if (objRegisterModel.strContactNumber)
      params.contact_number = objRegisterModel.strContactNumber

    if (objRegisterModel.strBirthDate)
      params.birthdate = objRegisterModel.strBirthDate

    if (objRegisterModel.strNationalID)
      params.idcard = objRegisterModel.strNationalID

    if (objRegisterModel.strGender) params.gender = objRegisterModel.strGender

    //OTP.
    if (objRegisterOTPModel) {
      if (objRegisterOTPModel.strOTP) params.otp = objRegisterOTPModel.strOTP

      if (objRegisterOTPModel.strRefCode)
        params.refcode = objRegisterOTPModel.strRefCode
    }

    //Device info.
    if (objRegisterDeviceInfoModel) {
      if (objRegisterDeviceInfoModel.strCarrier)
        params.carrier = objRegisterDeviceInfoModel.strCarrier

      if (objRegisterDeviceInfoModel.strClientVersion)
        params.client_version = objRegisterDeviceInfoModel.strClientVersion

      if (objRegisterDeviceInfoModel.strOperationSystem)
        params.os = objRegisterDeviceInfoModel.strOperationSystem

      if (objRegisterDeviceInfoModel.strPlatform)
        params.platform = objRegisterDeviceInfoModel.strPlatform

      if (objRegisterDeviceInfoModel.strMacAddress)
        params.mac_address = objRegisterDeviceInfoModel.strMacAddress
    }

    if (objRegisterAddressModel) {
      if (objRegisterAddressModel.strAddress)
        params.address = objRegisterAddressModel.strAddress

      if (objRegisterAddressModel.strZipcode)
        params.zipcode = objRegisterAddressModel.strZipcode

      if (objRegisterAddressModel.strProvinceCode)
        params.province_code = objRegisterAddressModel.strProvinceCode

      if (objRegisterAddressModel.strProvinceName)
        params.province_name = objRegisterAddressModel.strProvinceName

      if (objRegisterAddressModel.strDistrictCode)
        params.district_code = objRegisterAddressModel.strDistrictCode

      if (objRegisterAddressModel.strDistrictName)
        params.district_name = objRegisterAddressModel.strDistrictName

      if (objRegisterAddressModel.strSubDistrictCode)
        params.subdistrict_code = objRegisterAddressModel.strSubDistrictCode

      if (objRegisterAddressModel.strSubDistrictName)
        params.subdistrict_name = objRegisterAddressModel.strSubDistrictName

      if (objRegisterAddressModel.strLatitude)
        params.latitude = objRegisterAddressModel.strLatitude

      if (objRegisterAddressModel.strLongitude)
        params.longitude = objRegisterAddressModel.strLongitude
    }

    if (objRegisterOtherInfoModel) {
      if (objRegisterOtherInfoModel.strInfo)
        params.info = objRegisterOtherInfoModel.strInfo

      if (objRegisterOtherInfoModel.strCustomInfo)
        params.custom_info = objRegisterOtherInfoModel.strCustomInfo
    }

    var strUrl = this.config().bzbsUrl + '/auth/register'
    return BzbsApi.connectBzbsPost(strUrl, null, params, null, null)
  },

  /**
   * Change bzbs password
   * @param {string} strBzbsToken
   * @param {string} strCurrent Current password
   * @param {string} strChange New password
   */
  changePassword: function (strBzbsToken, strCurrent, strChange) {
    var params = {
      current: strCurrent,
      change: strChange,
    }

    var strUrl = this.config().bzbsUrl + '/profile/me/change_password'
    return BzbsApi.connectBzbsPost(strUrl, strBzbsToken, params, null, null)
  },
  updateThemes: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/themes?device_app_id=' +
      Vue.bzbsConfig.client_id +
      '&agencyId=' +
      params.agencyId
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postReward: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/reward?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      params.agencyId +
      '&type=' +
      params.type
    return BzbsApi.connectBzbsPost(strUrl, this.token(), null)
  },
  postAccountSetting: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/profile?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      params.agencyId
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getStampMerchant: function (input) {
    var params = {
      username: input.UserName,
      password: input.Password,
      terminalid: Vue.bzbsConfig.businessRule.default.terminalId,
      branchid: input.BranchId,
      brandid: input.BrandId,
    }
    var strUrl = this.config().bzbsApiWallet + '/merchant/login'
    return BzbsApi.connectBzbsPost(strUrl, null, params)
  },
}
