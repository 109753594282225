import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
import Vue from 'vue'

/**
 * @method apiAddtoCart
 * @method getSubscription
 * @method goShopingCart
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  local: function () {
    if (Cache.get('Locale') == 'th') {
      return '1054'
    } else {
      return '1033'
    }
  },

  apiAddtoCart: function (campaignId, arrItem) {
    var params = {}
    params.json = JSON.stringify(arrItem)
    params.mode = 'add'
    params.clearCart = true
    var strUrl =
      this.config().shopingmoduleCartUrl + '/cart/' + campaignId + '/add'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getSubscription: function () {
    var strUrl =
      this.config().shippingmoduleUrl + '/subscription/get_subscriptions?top=10'
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },
  goShopingCart: function (params) {
    var redirectUrl =
      this.config().shopingCartUrl +
      '/subscription' +
      '?brand=crmplus' +
      '&token=' +
      this.token() +
      '&subscription=true' +
      '&locale=' +
      this.local() +
      '&theme=ecommerce_brand_a' +
      '&multilang=true' +
      '&return_url=' +
      encodeURIComponent(window.location.origin + params.return_url) +
      '&success_url=' +
      encodeURIComponent(window.location.origin + params.success_url) +
      '&appid=' +
      this.config().client_id

    console.log('redirectUrl :: ', redirectUrl)
    window.location = redirectUrl
  },
  goShopingCartOrder: function (params, subscriptionId) {
    var redirectUrl =
      this.config().shopingCartUrl +
      '/orderdetail_subscription' +
      '?subscriptionId=' +
      subscriptionId +
      '&token=' +
      this.token() +
      '&locale=' +
      this.local() +
      '&appid=' +
      this.config().client_id +
      '&brand=crmplus' +
      '&theme=ecommerce_brand_a' +
      '&multilang=true' +
      '&subscription=true' +
      '&subscription=true' +
      '&IsStandardPackage=' +
      params.IsStandardPackage +
      '&return_url=' +
      encodeURIComponent(window.location.origin + params.return_url) +
      '&success_url=' +
      encodeURIComponent(window.location.origin + params.success_url)

    console.log('redirectUrl :: ', redirectUrl)
    window.location = redirectUrl
  },
}
