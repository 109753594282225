var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer",class:{ showing: !_vm.isShow }},[_c('div',{staticClass:"container-fluid mx-0 px-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-7 mx-0 px-0 footer-left-container"},[_c('router-link',{attrs:{"id":"footer_privacy_link","to":{
            name: 'about',
            params: {
              mode: 'privacy',
            },
          }}},[_vm._v(_vm._s(_vm.lbl['footer-menu-privacy'])+" ")]),_c('router-link',{attrs:{"id":"footer_contact_us_link","to":{
            name: 'about',
            params: {
              mode: 'contactus',
            },
          }}},[_vm._v(_vm._s(_vm.lbl['footer-menu-help'])+" ")])],1),_c('div',{staticClass:"col-sm-6 col-5 mx-0 px-0 footer-right-container"},[_c('img',{staticClass:"logo-new",attrs:{"alt":"Footer logo","src":_vm.logo}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }