<template>
  <div class="loader-svg" :class="{ loaded: !isShow }">
    <div class="box-loading">
      <div class="content-loading d-flex">
        <img src="@/assets/images/loading.gif" alt="" />
      </div>
      <div class="d-flex message-loading">{{ lbl['message-loading'] }}...</div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  name: 'Loading',
  props: {
    isShow: Boolean,
  },
  mixins: [Mixin],
  data: function () {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss">
.loader-svg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 7%);
  z-index: 9998;
  &.loaded {
    display: none;
  }
  .box-loading {
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    width: 11%;
    height: auto;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    .content-loading {
      width: 100%;
      justify-content: center;
      img {
        width: 50%;
      }
    }
    .message-loading {
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: #424242;
      margin-top: 2px;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .box-loading {
      width: 24% !important;
      padding: 0px;
      .content-loading {
        img {
          width: 60% !important;
        }
      }
      .message-loading {
        font-size: 10px;
      }
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 767px) and (orientation: landscape) {
    .box-loading {
      width: 15% !important;
      padding: 0px;
      .content-loading {
        img {
          width: 50% !important;
        }
      }
      .message-loading {
        font-size: 10px;
      }
    }
  }

  //แนวนอน mobile (iPhone x)
  @media only screen and (min-device-width: 768px) and (max-device-width: 812px) and (orientation: landscape) {
    .box-loading {
      width: 25% !important;
      padding: 0px;
      .content-loading {
        img {
          width: 60% !important;
        }
      }
      .message-loading {
        font-size: 10px;
      }
    }
  }

  //แนวตั้ง iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {
    .box-loading {
      width: 15% !important;
      .content-loading {
        img {
          width: 60% !important;
        }
      }
      .message-loading {
        font-size: 14px;
      }
    }
  }

  //แนวนอน iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
    .box-loading {
      width: 15% !important;
      .content-loading {
        img {
          width: 60% !important;
        }
      }
      .message-loading {
        font-size: 14px;
      }
    }
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 375px) and (orientation: portrait) {
    .box-loading {
      width: 30% !important;
      padding: 0px;
      .content-loading {
        img {
          width: 50% !important;
        }
      }
      .message-loading {
        font-size: 10px;
      }
    }
  }
}
</style>
