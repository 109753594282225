import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'

/**
 * @method apiGetPackage
 * @method apiGetNonStandardPackage
 * @method apiGetComparePackage
 * @method apiPostFreePackage
 * @method apiGetAccountInformation
 * @method apiAcknowledgePackageChange
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  agencyId: function () {
    return Cache.get('CRMUser').AgencyId
  },

  apiGetPackage: function () {
    let locale = '1033'
    if (Cache.get('Locale') == 'th') {
      locale = '1054'
    } else {
      locale = '1033'
    }
    var params = {}
    var agencyId = this.agencyId()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmpluspackage/short_packages?agencyId=' +
      agencyId +
      '&locale=' +
      locale
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  apiGetNonStandardPackage: function () {
    let locale = '1033'
    if (Cache.get('Locale') == 'th') {
      locale = '1054'
    } else {
      locale = '1033'
    }
    var params = {}
    var agencyId = this.agencyId()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmpluspackage/non_standard_short_packages?agencyId=' +
      agencyId +
      '&locale=' +
      locale
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  apiGetComparePackage: function () {
    let locale = '1033'
    if (Cache.get('Locale') == 'th') {
      locale = '1054'
    } else {
      locale = '1033'
    }
    var params = {}
    var agencyId = this.agencyId()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmpluspackage/full_packages?agencyId=' +
      agencyId +
      '&locale=' +
      locale
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  apiPostFreePackage: function (packageId) {
    var params = {}
    var agencyId = this.agencyId()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusaccount/select_free_package?agencyId=' +
      agencyId +
      '&packageId=' +
      packageId
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  apiGetAccountInformation: function () {
    let locale = '1033'
    if (Cache.get('Locale') == 'th') {
      locale = '1054'
    } else {
      locale = '1033'
    }
    var params = {}
    var agencyId = this.agencyId()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusaccount/account_information?agencyId=' +
      agencyId +
      '&locale=' +
      locale
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  apiAcknowledgePackageChange: function () {
    var params = {}
    var agencyId = this.agencyId()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusaccount/acknowledge_package_change?agencyId=' +
      agencyId
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  apiAccountPermissionChange: function (packageId) {
    var params = {
      agencyId: this.agencyId(),
      packageId: packageId,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusaccount/account_permission_change'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
