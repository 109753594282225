import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Locale from '@/helper/locale.js'

export default {
  /**
   *
   * @method getConfigThirdParty
   * @method getConfigQrcode
   * @method getCookies
   * @method getConfigPermission
   * @method getConfigTutorial
   *
   */

  config: function () {
    return Vue.bzbsConfig
  },
  getConfigThirdParty() {
    var bzbsBlobUrl =
      this.config().bzbsBlobUrl +
      '/config/crmplus/3rd-party/3rd-party-config.json'

    return new Promise((resolve, reject) => {
      BzbsApi.connectBzbsGetBlob(bzbsBlobUrl)
        .then(response => {
          var blob = response.data
          resolve(blob)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getConfigQrcode() {
    var bzbsBlobUrl =
      this.config().bzbsBlobUrl + '/config/crmplus/qr-code/data-qr-code.json'
    return new Promise((resolve, reject) => {
      BzbsApi.connectBzbsGetBlob(bzbsBlobUrl)
        .then(response => {
          var blob = response.data
          resolve(blob)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getCookies() {
    var locale = Locale.getLocale()
    var urlLocale =
      this.config().bzbsBlobUrl +
      '/config/crmplus/cookies/' +
      locale +
      '/cookies_' +
      locale +
      '.json'
    return new Promise((resolve, reject) => {
      BzbsApi.connectBzbsGetBlob(urlLocale)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getConfigPermission() {
    var bzbsBlobUrl =
      this.config().bzbsBlobUrl +
      '/config/crmplus/permission/permission-config.json'

    return new Promise((resolve, reject) => {
      BzbsApi.connectBzbsGetBlob(bzbsBlobUrl)
        .then(response => {
          var blob = response.data
          resolve(blob)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVersion(localhost) {
    var bzbsBlobUrl

    if (localhost) {
      bzbsBlobUrl = 'https://crm-plus-backoffice.buzzebees-dev.com/api/version'
      // bzbsBlobUrl = 'https://crm-plus-backoffice.buzzebees-uat.com/api/version'
    } else {
      bzbsBlobUrl = window.location.origin + '/api/version'
    }

    return new Promise((resolve, reject) => {
      BzbsApi.connectBzbsGetBlob(bzbsBlobUrl)
        .then(response => {
          var blob = response.data
          resolve(blob)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getConfigTutorial() {
    var bzbsUrlConfig =
      this.config().bzbsBlobUrl +
      '/config/crmplus/vdo-tutorial/tutorialconfig.json'

    return new Promise((resolve) => {
      BzbsApi.connectBzbsGetBlob(bzbsUrlConfig)
        .then(response => {
          var result = response.data
          resolve(result)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },
}
