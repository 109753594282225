export default {
    install(Vue) {
        Vue.CampaignType = {
            Draw: 0,
            Free: 1,
            Deal: 2,
            Buy: 3,
            Bid: 4,
            Ads: 5,
            Install: 6,
            Booking: 7,
            Interface: 8,
            Event: 9,
            Media: 10,
            EWalletTopup: 11,
            EWalletRedeem: 12,
            EWalletTranfer: 13,
            EWalletBanking: 14,
            AutoFeed: 15,
            News: 16,
            Reservation: 17,
            EWalletBuy: 18,
            PointRedemtion: 19,
            Donate: 20,
            PointFree: 21,
            Voucher: 22
        };
    }
};