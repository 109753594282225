import Vue from 'vue'
import Util from '@/helper/Utility.js'
import Locale from '@/helper/locale.js'
import BzbsAuth from '@/core/Account/service/BzbsAuth.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile.js'
import BzbsPackage from '@/core/Package/service/BzbsPackage'
import BzbsSettingsStore from '@/core/Settings/Store/BzbsSettingsStore'
import BzbsCart from '@/core/Cart/service/BzbsCart'
import {
  ProfileModel,
  ProfileAddressModel,
  ProfileShippingAddressModel,
} from '@/core/Account/model/AccountModel.js'

//Main key.
const KEY_COOKIE_USER = 'CRMUser'
const KEY_COOKIE_PROFILE = 'Profile'
const KEY_COOKIE_PROFILE_ADDRESS = 'ProfileAddress'
const KEY_COOKIE_PROFILE_SHIPPING_ADDRESS = 'ProfileShippingAddress'
const KEY_COOKIE_CONSENT = 'vue-cookie-accept-decline-myCookieCRMPlusBackoffice'
const KEY_COOKIE_STAMPMERCHANT = 'TokenStampMerc'
const KEY_COOKIE_PACKAGE = 'CRMPackage'
const KEY_COOKIE_PROFILE_CONFIG = 'ProfileConfig'

/**
 * Token
 * @method getToken
 * @method updateToken
 * @method getStoreType
 *
 * Cache
 * @method getCacheUser
 * @method setCacheUser
 * @method removeCacheUser
 * @method getCachePoint
 * @method updatePoint
 * @method getCacheCustom
 * @method setCacheCustom
 * @method removeCacheCustom
 *
 * Login
 * @method setLogin
 * @method setLogout
 * @method loginStatus
 * @method isLogin
 *
 * Api login
 * @method apiGetPoint
 * @method apiBzbsLogin
 * @method apiDeviceLogin
 * @method apiFBLogin
 * @method apiLogout
 *
 * Cache profile
 * @method getCacheProfile
 * @method setCacheProfile
 * @method removeCacheProfile
 * @method getCacheProfileAddress
 * @method setCacheProfileAddress
 * @method removeCacheProfileAddress
 * @method getCacheProfileShippingAddres
 * @method setCacheProfileShippingAddres
 * @method removeCacheProfileShippingAddres
 * @method setCacheAllProfile
 * @method removeCacheAllProfile
 *
 * Api profile
 * @method apiGetProfile
 * @method apiUpdateProfile
 * @method apiUpdateProfileLocale
 * @method apiUpdateProfileShippingAddress
 *
 * Analytic Matomo
 * @method bzbsAnalyticTracking
 *
 * Shopping Cart
 * @method getSubscriptionInfo
 */
export default {
  initial: function () {
    if (Vue.$cookies.get(KEY_COOKIE_USER)) {
      Vue.initFinished = false
      return new Promise((resolve, reject) => {
        Promise.all([Locale.getBlobLbl()])
          .then(() => {
            Vue.initFinished = true
            resolve()
          })
          .catch(() => {
            Vue.initFinished = true
            reject()
          })
      })
    } else {
      if (window.location.hash.includes('about')) {
        return new Promise(resolve => {
          Locale.getBlobLbl().then(() => {
            Vue.initFinished = true
            resolve()
          })
        })
      } else {
        return new Promise(resolve => {
          Locale.getBlobLbl().then(() => {
            Vue.initFinished = true
            resolve()
          })
        })
      }
    }
  },
  //#region user
  /**
   * Get user token.
   * @return {string|null} user token.
   */
  getToken: function () {
    return this.getCacheUser() ? this.getCacheUser().Token : null
  },

  /**
   * Update user token.
   * @param {Object} strToken
   * @return {object} user.
   */
  updateToken: function (strToken) {
    var objUser = this.getCacheUser()
    objUser.token = strToken
    this.setCacheUser(objUser)

    return objUser
  },

  getStoreType: function () {
    return this.getCacheUser() ? this.getCacheUser().StoreType : null
  },

  /**
   * Get cache user.
   * @return {object} Cache user.
   */
  getCacheUser: function () {
    return Vue.$cookies.get(KEY_COOKIE_USER)
  },

  /**
   * Set cache user.
   * @param {object} objUser
   */
  setCacheUser: function (objUser) {
    this.removeCacheUser()
    Vue.$cookies.set(
      KEY_COOKIE_USER,
      JSON.stringify(objUser),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },

  /**
   * Get point.
   * @return {number} User point.
   */
  getCachePoint: function () {
    if (this.isLogin()) {
      var objUser = this.getCacheUser()
      if (
        objUser.updated_points != null &&
        objUser.updated_points.points != null
      ) {
        return objUser.updated_points.points
      }
    }

    return 0
  },

  /**
   * Remove cache user.
   */
  removeCacheUser: function () {
    Vue.$cookies.remove(KEY_COOKIE_USER)
  },

  /**
   * Set user login.
   * @param {object} objUser
   */
  setLogin: function (loginOutput, companyProfileOutput) {
    var companyItem = []
    if (loginOutput.data.detail != null) {
      if (loginOutput.data.detail.companies.length > 0) {
        companyItem = loginOutput.data.detail.companies[0]
      }
    }
    var user = {
      Token: loginOutput.data.token,
      AppId: companyProfileOutput.data.AppId,
      UserId: loginOutput.data.userId,
      AgencyId: companyItem.AgencyId ? companyItem.AgencyId : '',
      LogoUrl: companyProfileOutput.data.LogoUrl
        ? companyProfileOutput.data.LogoUrl
        : '',
      BusinessName: companyProfileOutput.data.BusinessName
        ? companyProfileOutput.data.BusinessName
        : '',
      StoreType: companyProfileOutput.data.StoreType
        ? companyProfileOutput.data.StoreType
        : '',
      OwnerUserId: companyProfileOutput.data.OwnerUserId,
      Language: navigator.language || navigator.userLanguage,
      isShowImportTab: (companyProfileOutput.data.IsAllowImportBatch
        ? companyProfileOutput.data.IsAllowImportBatch
        : false
      ).toString(),
      isPointMode: (companyProfileOutput.data.StoreType
        ? companyProfileOutput.data.StoreType
        : false
      ).toString(),
      isShowImportUserTab: (companyProfileOutput.data.IsCompany
        ? companyProfileOutput.data.IsCompany
        : false
      ).toString(),
      hasUserlevel: (companyProfileOutput.data.HasUserlevel
        ? companyProfileOutput.data.HasUserlevel
        : false
      ).toString(),
      HideDashboard: (companyProfileOutput.data.HideDashboard
        ? companyProfileOutput.data.HideDashboard
        : false
      ).toString(),
      HideAccountSetup: (companyProfileOutput.data.HideAccountSetup
        ? companyProfileOutput.data.HideAccountSetup
        : false
      ).toString(),
      HideLineSetup: (companyProfileOutput.data.HideLineSetup
        ? companyProfileOutput.data.HideLineSetup
        : false
      ).toString(),
      HideManageUser: (companyProfileOutput.data.HideManageUser
        ? companyProfileOutput.data.HideManageUser
        : false
      ).toString(),
      HideThemeSetting: (companyProfileOutput.data.HideThemeSetting
        ? companyProfileOutput.data.HideThemeSetting
        : false
      ).toString(),
      DisableCreatePointSetup: (companyProfileOutput.data
        .DisableCreatePointSetup
        ? companyProfileOutput.data.DisableCreatePointSetup
        : false
      ).toString(),
      FixPointUsedToZero: (companyProfileOutput.data.FixPointUsedToZero
        ? companyProfileOutput.data.FixPointUsedToZero
        : false
      ).toString(),
      HideMemberDetail: (companyProfileOutput.data.HideMemberDetail
        ? companyProfileOutput.data.HideMemberDetail
        : false
      ).toString(),
      CrmPlusRole: companyItem.CrmPlusRole ? companyItem.CrmPlusRole : '',
      PosRole: companyItem.PosRole ? companyItem.PosRole : '',
      Role: companyItem.Role ? companyItem.Role : '',
    }
    this.setCacheUser(user)

    BzbsProfile.getProfileName().then(response => {
      var objProfile = new ProfileModel(response.data)
      this.setCacheProfile(objProfile)
    })
  },

  /**
   * Set user logout.
   * @return {promises} Logout response.
   */
  setLogout: function () {
    this.removeCacheUser()
    this.removeCacheAllProfile()
    this.removeCacheStampMerchant()
    this.removeCachePackage()

    return this.apiLogout()
  },

  /**
   * Update point.
   * @param {object} objUpdatePoints
   */
  updatePoint: function (objUpdatePoints) {
    var objUser = this.getCacheUser()
    var objProfile = this.getCacheProfile()
    objUser.updated_points = objUpdatePoints
    objProfile.updated_points = objUpdatePoints
    this.setCacheUser(objUser)
    this.setCacheProfile(objProfile)
  },

  /**
   * Check login wait for check token status.
   * @return Promise
   */
  loginStatus: function () {
    return new Promise(resolve => {
      if (Vue.initFinished) {
        var strToken = this.getToken()
        var rs = !Util.isEmptyStr(strToken)
        resolve(rs)
      } else {
        var itv = setInterval(() => {
          if (Vue.initFinished) {
            var strToken = this.getToken()
            var rs = !Util.isEmptyStr(strToken)
            clearInterval(itv)
            resolve(rs)
          }
        }, 200)
      }
    })
  },

  /**
   * Check login.
   * @return {boolean} Login status.
   */
  isLogin: function () {
    var strToken = this.getToken()
    return !Util.isEmptyStr(strToken)
  },

  /**
   * Get user point.
   * @returns {promises} Update point object.
   */
  apiGetPoint: function () {
    return new Promise((resolve, reject) => {
      var strToken = this.getToken()
      BzbsProfile.getPoint(strToken)
        .then(response => {
          this.updatePoint(response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  syncApplication(appId, name, logo, type, email) {
    let params = {
      application_id: appId,
      name: name,
      description: name,
      logo_url: logo,
      is_active: true,
      role_type: type, //CRMPlus HivePlus BEEsBenefit
      email: email,
    }

    return new Promise(resolve => {
      BzbsSettingsStore.syncApplicationPermission(params)
        .then(response => {
          console.log(response)
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  /**
   * Bzbs login.
   * @param {string} strUsername
   * @param {string} strPassword
   * @return {object} Return user and profile.
   */
  apiBzbsLogin: function (strUsername, strPassword) {
    return new Promise((resolve, reject) => {
      BzbsAuth.apiLogin(strUsername, strPassword, null, null, null, null, false)
        .then(response => {
          console.log('apiLogin : ', response.data)

          var Agency_Id = 0
          if (response.data.detail != null) {
            if (response.data.detail.companies.length > 0) {
              Agency_Id = response.data.detail.companies[0].AgencyId

              let input = {
                AgencyId: Agency_Id,
                Token: response.data.token,
              }

              this.apiGetProfile(input)
                .then(objProfile => {
                  this.setLogin(response, objProfile)
                  resolve(response.data)
                })
                .catch(error => {
                  reject(error)
                })
            }
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  apiBzbsLoginRegister: function (strUsername, strPassword, strMobileNumber) {
    return new Promise((resolve, reject) => {
      BzbsAuth.apiLogin(
        strUsername,
        strPassword,
        strMobileNumber,
        null,
        null,
        null,
        false,
      )
        .then(response => {
          console.log('apiLogin : ', response.data)

          var Agency_Id = 0
          //var strBranchId = '';
          if (response.data.detail != null) {
            if (response.data.detail.companies.length > 0) {
              Agency_Id = response.data.detail.companies[0].AgencyId
              //strBranchId = JSON.parse(response.data.detail.companies[0].BranchJson).toString();

              let input = {
                AgencyId: Agency_Id,
                Token: response.data.token,
              }
              this.apiGetProfile(input)
                .then(objProfile => {
                  this.setLogin(response, objProfile, null)

                  var objResult = {
                    user: response.data,
                    profile: objProfile,
                    tokenstampmerc: null,
                  }
                  resolve(objResult)
                })
                .catch(error => {
                  reject(error)
                })
            }
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  apiDeviceLogin: function () {},

  apiFBLogin: function () {},

  /**
   * Logout.
   */
  apiLogout: function () {
    return new Promise((resolve, reject) => {
      var strToken = this.getToken()
      if (!strToken) {
        resolve(null)
      } else {
        BzbsAuth.apiLogout(strToken, null, null)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  //#endregion user

  //#region profile
  /**
   * Get cache profile.
   * @return {object|null} Profile.
   */
  getCacheProfile: function () {
    return Vue.$cookies.get(KEY_COOKIE_PROFILE)
  },

  /**
   * Set cache profile.
   * @param {object|null} objProfile
   */
  setCacheProfile: function (objProfile) {
    this.removeCacheProfile()
    Vue.$cookies.set(
      KEY_COOKIE_PROFILE,
      JSON.stringify(objProfile),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },

  /**
   * Remove cache profile.
   */
  removeCacheProfile: function () {
    Vue.$cookies.remove(KEY_COOKIE_PROFILE)
  },

  /**
   * Get cache profile address.
   * @return {object|null}
   */
  getCacheProfileAddress: function () {
    return Vue.$cookies.get(KEY_COOKIE_PROFILE_ADDRESS)
  },

  /**
   * Set cache profile address.
   * @param {object|null} objProfileAddress
   */
  setCacheProfileAddress: function (objProfileAddress) {
    this.removeCacheProfileAddress()
    Vue.$cookies.set(
      KEY_COOKIE_PROFILE_ADDRESS,
      JSON.stringify(objProfileAddress),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },

  /**
   * Remove cache profile address.
   */
  removeCacheProfileAddress: function () {
    Vue.$cookies.remove(KEY_COOKIE_PROFILE_ADDRESS)
  },

  /**
   * Get cache profile shipping address.
   * @return {object|null} Profile shipping address
   */
  getCacheProfileShippingAddres: function () {
    return Vue.$cookies.get(KEY_COOKIE_PROFILE_SHIPPING_ADDRESS)
  },

  /**
   * Set cache profile shipping address.
   * @param {object|null} objProfileShippingAddress
   */
  setCacheProfileShippingAddres: function (objProfileShippingAddress) {
    this.removeCacheProfileShippingAddres()
    Vue.$cookies.set(
      KEY_COOKIE_PROFILE_SHIPPING_ADDRESS,
      JSON.stringify(objProfileShippingAddress),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },
  /**
   * Remove cache profile shipping address.
   */
  removeCacheProfileShippingAddres: function () {
    Vue.$cookies.remove(KEY_COOKIE_PROFILE_SHIPPING_ADDRESS)
  },

  /**
   * Set cache for profile, profile address, profile shipping address.
   * @param {object|null} objProfile
   * @param {object|null} objProfileAddress
   * @param {object|null} objProfileShippingAddress
   */
  setCacheAllProfile: function (
    objProfile,
    objProfileAddress,
    objProfileShippingAddress,
  ) {
    console.log('objProfile :: ', objProfile)
    this.removeCacheAllProfile()
    // this.setCacheProfile(objProfile)
    this.setCacheProfileAddress(objProfileAddress)
    this.setCacheProfileShippingAddres(objProfileShippingAddress)
  },

  /**
   * Remove all profile cache.
   */
  removeCacheAllProfile: function () {
    // this.removeCacheProfile()
    this.removeCacheProfileAddress()
    this.removeCacheProfileShippingAddres()
  },

  /**
   * Get profile.
   * @return {object|null} Profile.
   */
  apiGetProfile: function (input) {
    return new Promise((resolve, reject) => {
      if (!input) {
        resolve(null)
      } else {
        BzbsProfile.getProfile(input)
          .then(response => {
            console.log('getProfile : ', response)
            var objProfile = new ProfileModel(response.data)
            var objProfileAddress = new ProfileAddressModel(response.data)
            var objProfileShippingAddress = new ProfileShippingAddressModel(
              response.data,
            )
            this.setCacheAllProfile(
              objProfile,
              objProfileAddress,
              objProfileShippingAddress,
            )
            resolve(response)
          })
          .catch(error => {
            this.removeCacheUser()
            this.removeCacheAllProfile()
            reject(error)
          })
      }
    })
  },

  /**
   * Update user profile.
   * @param {object} objProfile
   * @param {object} objProfileAddress
   * @param {string} strExtendJson
   */
  apiUpdateProfile: function (objProfile, objProfileAddress, strExtendJson) {
    return new Promise((resolve, reject) => {
      var strToken = this.getToken()
      if (!strToken) {
        resolve(null)
      } else {
        BzbsProfile.updateProfile(
          strToken,
          objProfile,
          objProfileAddress,
          strExtendJson,
        )
          .then(response => {
            //Update cache profile.
            var objNewProfile = new ProfileModel(response.data)
            var objNewProfileAddress = new ProfileAddressModel(response.data)
            var objNewProfileShippingAddress = new ProfileShippingAddressModel(
              response.data,
            )
            this.setCacheAllProfile(
              objNewProfile,
              objNewProfileAddress,
              objNewProfileShippingAddress,
            )
            //Update cache user.
            var objUser = this.getCacheUser()
            objUser.token = response.data.Token
            objUser.updated_points = objNewProfile.updated_points
            this.setCacheUser(objUser)

            var objResult = {
              objUser: objUser,
              objProfile: objProfile,
            }
            resolve(objResult)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },

  /**
   * Update user locale.
   * @param {number} localeCode
   */
  apiUpdateProfileLocale: function (localeCode) {
    var objProfile = this.getCacheProfile()
    if (objProfile) objProfile.locale = localeCode

    var objProfileAddress = this.getCacheProfileAddress()

    return this.apiUpdateProfile(objProfile, objProfileAddress)
  },

  /**
   * Update user shipping address.
   * @param {object} objProfileShippingAddress AddressModel /Model/AddressModel.js
   */
  apiUpdateProfileShippingAddress: function (objProfileShippingAddress) {
    return new Promise((resolve, reject) => {
      var strToken = this.getToken()
      if (!strToken) {
        resolve(null)
      } else {
        BzbsProfile.updateShippingAddress(strToken, objProfileShippingAddress)
          .then(objResult => {
            var objNewProfileShippingAddress = new ProfileShippingAddressModel(
              objResult,
            )
            this.setCacheProfileShippingAddres(objNewProfileShippingAddress)
            resolve(objNewProfileShippingAddress)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  //#endregion profile

  //#region general function
  /**
   * Get cache json by key.
   * @param {string} strCookieKey
   * @return {object|null} Profile extend json.
   */
  getCacheCustom: function (strCookieKey) {
    return Vue.$cookies.get(strCookieKey)
  },

  /**
   * Set cache json by key.
   * @param {string} strCookieKey
   * @param {object|null} objData
   */
  setCacheCustom: function (strCookieKey, objData) {
    this.removeCacheCustom(strCookieKey)
    Vue.$cookies.set(
      strCookieKey,
      JSON.stringify(objData),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },

  /**
   * Remove cache extend json.
   * @param {string} strCookieKey
   */
  removeCacheCustom: function (strCookieKey) {
    Vue.$cookies.remove(strCookieKey)
  },
  //#endregion general function

  //#region cookie consent function
  getCacheCookieConsent: function () {
    return localStorage.getItem(KEY_COOKIE_CONSENT)
  },
  setCacheCookieConsent: function (obj) {
    this.removeCacheCookieConsent()
    localStorage.setItem(KEY_COOKIE_CONSENT, JSON.stringify(obj))
  },
  removeCacheCookieConsent: function () {
    localStorage.removeItem(KEY_COOKIE_CONSENT)
  },
  //#endregion cookie consent function

  apiLoginStampMerchant: function (strUsername, strPassword, data) {
    return new Promise((resolve, reject) => {
      if (!strUsername && !strPassword && !data) {
        resolve(null)
      } else {
        let params = {}
        var strBrandId = ''
        var strBranchId = ''

        if (data.detail != null) {
          if (data.detail.companies.length > 0) {
            strBrandId = data.detail.companies[0].AgencyId.toString().padStart(
              7,
              '0',
            )

            let input = {
              AgencyId: data.detail.companies[0].AgencyId,
              Token: data.token,
            }
            //For get branch data.
            BzbsProfile.getProfile(input).then(response => {
              if (response.data.Stores) {
                //Check HQ for prduction issue Olino.
                for (let i = 0; i < response.data.Stores.length; i++) {
                  if (response.data.Stores[i].IsHeadOffice) {
                    strBranchId = response.data.Stores[i].ID
                    break
                  }
                }
              }
              if (strBranchId == '') {
                strBranchId = JSON.parse(
                  data.detail.companies[0].BranchJson,
                ).toString()
                if (strBranchId) {
                  strBranchId = strBranchId.split(',', 1)
                  if (strBranchId.length > 0) {
                    strBranchId = strBranchId[0]
                  }
                }
              }
              params = {
                UserName: strUsername,
                Password: strPassword,
                BrandId: strBrandId,
                BranchId: strBranchId,
              }

              BzbsProfile.getStampMerchant(params)
                .then(response => {
                  if (response.status == 200) {
                    var tokenStampMerc = response.data.token
                    this.removeCacheStampMerchant()
                    this.setCacheStampMerchant(tokenStampMerc)
                  } else {
                    this.removeCacheStampMerchant()
                    this.setCacheStampMerchant('')
                  }
                  resolve(response)
                })
                .catch(error => {
                  this.removeCacheStampMerchant()
                  this.setCacheStampMerchant('')
                  reject(error)
                })
            })
          }
        }
      }
    })
  },
  getCacheStampMerchant: function () {
    return Vue.$cookies.get(KEY_COOKIE_STAMPMERCHANT)
  },

  setCacheStampMerchant: function (objMerchant) {
    this.removeCacheStampMerchant()
    // Bug-344541 Clear double quotes or single quote before set cookies
    var data = JSON.stringify(objMerchant)
    data = data.replace(/["']/g, '')
    Vue.$cookies.set(
      KEY_COOKIE_STAMPMERCHANT,
      data,
      null,
      null,
      null,
      true,
      'Strict',
    )
  },

  removeCacheStampMerchant: function () {
    Vue.$cookies.remove(KEY_COOKIE_STAMPMERCHANT)
  },

  updateLogoUrl: function (pathUrl) {
    var objUser = this.getCacheUser()
    objUser.LogoUrl = pathUrl
    this.setCacheUser(objUser)

    return objUser
  },
  apiAccountInformation: function () {
    return new Promise((resolve, reject) => {
      return BzbsPackage.apiGetAccountInformation()
        .then(res => {
          this.setCachePackage(res.data.data)
          return resolve(res.data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  },
  setCachePackage: function (objPackage) {
    this.removeCachePackage()
    Vue.$cookies.set(
      KEY_COOKIE_PACKAGE,
      JSON.stringify(objPackage),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },
  getCachePackage: function () {
    return Vue.$cookies.get(KEY_COOKIE_PACKAGE)
  },
  removeCachePackage: function () {
    Vue.$cookies.remove(KEY_COOKIE_PACKAGE)
  },
  bzbsAnalyticTracking: function (Page, Category, Action, Name) {
    var _paq = (window._paq = window._paq || [])
    if (Vue.bzbsConfig.env !== 'dev') {
      _paq.push(['setDocumentTitle', Page])
      _paq.push(['trackPageView'])
      _paq.push(['setCustomVariable', 1, 'Category', Page, 'page'])
      _paq.push(['trackEvent', Category, Action, Name])
      _paq.push(['enableLinkTracking'])
      ;(function () {
        var u = Vue.bzbsConfig.bzbsAnalyticUrl
        _paq.push(['setTrackerUrl', u + 'matomo.php'])
        _paq.push(['setSiteId', Vue.bzbsConfig.bzbsAnalyticId])
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0]
        g.type = 'text/javascript'
        g.async = true
        g.src = u + 'matomo.js'
        s.parentNode.insertBefore(g, s)
        console.log('====bzbsAnalyticTracking====')
      })()
    }
  },
  getSubscriptionInfo() {
    return new Promise((resolve, reject) => {
      return BzbsCart.getSubscription()
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          return reject(err)
        })
    })
  },
  apiProfileConfig() {
    let user = this.getCacheUser()
    if (user) {
      return new Promise((resolve, reject) => {
        let input = {
          AgencyId: user.AgencyId,
          Token: user.Token,
        }
        this.apiGetProfile(input)
          .then(objProfile => {
            this.setCacheProfileConfig(objProfile)
            resolve(objProfile)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  setCacheProfileConfig: function (objProfile) {
    this.removeCacheProfileConfig()
    let config = {
      ShownAnnouncement: objProfile.data.ShownAnnouncement,
      isShowImportTab: objProfile.data.IsAllowImportBatch,
      isPointMode: objProfile.data.StoreType,
      isShowImportUserTab: objProfile.data.IsCompany,
      hasUserlevel: objProfile.data.HasUserlevel,
      HideDashboard: objProfile.data.HideDashboard,
      HideAccountSetup: objProfile.data.HideAccountSetup,
      HideLineSetup: objProfile.data.HideLineSetup,
      HideManageUser: objProfile.data.HideManageUser,
      HideThemeSetting: objProfile.data.HideThemeSetting,
      DisableCreatePointSetup: objProfile.data.DisableCreatePointSetup,
      FixPointUsedToZero: objProfile.data.FixPointUsedToZero,
      HideMemberDetail: objProfile.data.HideMemberDetail,
      ShowNewDashboard: objProfile.data.ShowNewDashboard
        ? objProfile.data.ShowNewDashboard
        : false,
    }
    Vue.$cookies.set(
      KEY_COOKIE_PROFILE_CONFIG,
      JSON.stringify(config),
      null,
      null,
      null,
      true,
      'Strict',
    )
  },
  removeCacheProfileConfig: function () {
    Vue.$cookies.remove(KEY_COOKIE_PROFILE_CONFIG)
  },
  getCacheProfileConfig: function () {
    return Vue.$cookies.get(KEY_COOKIE_PROFILE_CONFIG)
  },
  apiAccountPermissionChange: function (objPackage) {
    if (objPackage) {
      if (objPackage.PackageId) {
        return new Promise((resolve, reject) => {
          return BzbsPackage.apiAccountPermissionChange(objPackage.PackageId)
            .then(res => {
              return resolve(res.data)
            })
            .catch(err => {
              return reject(err)
            })
        })
      }
    }
  },
  filterLoginTypeSOB(list) {
    if (list.length) {
      list.forEach(item => {
        var isSOB = item.Details.filter(detail => detail.SOB == true)
        item.Details = isSOB
      })
      return list
    } else return list
  },
}
