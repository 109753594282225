import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'

/**
 * @method apiRequestOTP
 * @method apiLogin
 * @method apiLogout
 * @method apiLoginLine
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },

  /**
   * Request OTP
   * @param {Object} authRequestOTPParams AuthRequestOTPModel.getParams() /service/config/AuthConfigModel.js
   */
  apiRequestOTP: function (authRequestOTPParams) {
    var url = this.config().bzbsUrl + '/auth/otp'
    return BzbsApi.connectBzbsGet(url, null, authRequestOTPParams)
  },
  apiConfirmOTP: function (params) {
    var url =
      this.config().bzbsUrl +
      '/auth/validate_otp?otp=' +
      params.otp +
      '&refcode=' +
      params.refcode +
      '&app_id=' +
      params.app_id +
      '&channel=' +
      params.channel
    return BzbsApi.connectBzbsPost(url, null, params)
  },
  /**
   * @param {String} username
   * @param {String} password
   * @param {String} strUUID
   * @param {String} strDeviceToken
   * @param {String} strClientVersion
   * @param {Boolean} isDeviceNotiEnable
   */
  apiLogin: function (
    username,
    password,
    mobile,
    strUUID,
    strDeviceToken,
    strClientVersion,
    isDeviceNotiEnable,
  ) {
    var params = {
      username: username,
      password: password,
      contact_number: mobile,
      uuid: strUUID,
      app_id: this.config().client_id,
      os: 'web',
      info: '{"service":"crmplus"}',
      platform: 'web',
      mac_address: strUUID,
      device_noti_enable: isDeviceNotiEnable,
      client_version: strClientVersion,
      device_token: strDeviceToken,
    }

    var url = this.config().bzbsUrl + '/auth/bzbs_login'
    return BzbsApi.connectBzbsPost(url, null, params)
  },

  /**
   * @param {String} strBzbsToken
   * @param {String} strFBToken
   * @param {String} strUUID
   */
  apiLogout: function (strBzbsToken, strFBToken, strUUID) {
    var params = {
      uuid: strUUID,
      access_token: strFBToken,
    }

    var url = this.config().bzbsUrl + '/auth/logout'
    return BzbsApi.connectBzbsPost(url, strBzbsToken, params)
  },

  /**
   * @param {string} lineIdToken
   */
  apiLoginLine: function (lineIdToken) {
    var params = {
      app_id: this.config().client_id,
      id_token: lineIdToken,
    }
    var url = this.config().bzbsUrl + '/auth/line_login'
    return BzbsApi.connectBzbsPost(url, null, params)
  },
}
