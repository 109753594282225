export default {
    install(Vue) {
        Vue.RegExp = {
            emoji: /((\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]\s?)+)/g,
            number: /\d/g,
            notNumber: /[^0-9]/g,
            password: /^[A-Za-z\d]/g,
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            zipcode: /^\d{5}$/,
            symbol: /[-!$@฿#%^&*()_+|~=`{}[\]:";'<>?,./]/g
        }
    }
}