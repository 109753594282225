import moment from 'moment';
import Locale from '@/helper/locale.js';

export default {
	/********* Datetime *********
   * @function convertDateToTimeStampUnix
   * @function isDatetimeValid
   ****************************/
	convertDateToTimeStampUnix: function(date) {
		var unixTime = (date.getTime() - date.getTimezoneOffset() * 60 * 1000) / 1000;
		return unixTime;
	},
	isDatetimeValid: function(y, m, d) {
		var year = y;
		var month = m < 10 ? '0' + m : m;
		var day = d < 10 ? '0' + d : d;
		var dateFormat = year + '-' + month + '-' + day;

		return moment(dateFormat).isValid();
	},

	/********** Date Format ***********
   * @function dateFullFormat
   * @function getYearByLocale
   * @function timeFormat
   **********************************/
	dateFullFormat: function(timestamp) {
		let locale = Locale.getLocaleCode();
		if (locale != 'th') locale = 'en';
		moment.locale(locale);
		var strDate = moment.unix(timestamp).format('DD MMMM ');
		var year = moment.unix(timestamp).format('YYYY');
		year = this.getYearByLocale(year);

		return strDate + year;
	},
	dateShortFormat: function(timestamp) {
		let locale = Locale.getLocaleCode();
		if (locale != 'th') locale = 'en';
		moment.locale(locale);
		var strDate = moment.unix(timestamp).format('DD MMM ');
		var year = moment.unix(timestamp).format('YYYY');
		year = this.getYearByLocale(year);

		return strDate + year;
	},
	dateFullFormatUTC: function(timestamp) {
		let locale = Locale.getLocaleCode();
		if (locale != 'th') locale = 'en';
		moment.locale(locale);
		var strDate = moment.unix(timestamp).utc().format('DD MMMM ');
		var year = moment.unix(timestamp).utc().format('YYYY');
		year = this.getYearByLocale(year);

		return strDate + year;
	},
	dateShortFormatUTC: function(timestamp) {
		let locale = Locale.getLocaleCode();
		if (locale != 'th') locale = 'en';
		moment.locale(locale);
		var strDate = moment.unix(timestamp).utc().format('DD MMM ');
		var year = moment.unix(timestamp).utc().format('YYYY');
		year = this.getYearByLocale(year);

		return strDate + year;
	},
	datetimeFullFormatUTC: function(timestamp) {
		let locale = Locale.getLocaleCode();
		if (locale != 'th') locale = 'en';
		moment.locale(locale);
		var strDate = moment.unix(timestamp).utc().format('DD MMMM ');
		var year = moment.unix(timestamp).utc().format('YYYY');
		year = this.getYearByLocale(year);

		var time = moment.unix(timestamp).utc().format('HH:mm');
		return strDate + year + ' ' + time;
	},
	datetimeShortFormatUTC: function(timestamp) {
		let locale = Locale.getLocaleCode();
		if (locale != 'th') locale = 'en';
		moment.locale(locale);
		var strDate = moment.unix(timestamp).utc().format('DD MMM ');
		var year = moment.unix(timestamp).utc().format('YYYY');
		year = this.getYearByLocale(year);

		var time = moment.unix(timestamp).utc().format('HH:mm');
		return strDate + year + ' ' + time;
	},
	getYearByLocale: function(year) {
		if (Locale.getLocaleCode() == 'th') {
			return parseInt(year) + 543;
		} else {
			return year;
		}
	},
	timeFormatUTC: function(timestamp) {
		var strDate = moment.unix(timestamp).utc().format('HH:mm');
		return strDate;
	},
	timeFormat: function(timestamp) {
		var strDate = moment.unix(timestamp).format('HH:mm:ss');
		return strDate;
	}
};
