import axios from 'axios'
import Vue from 'vue'
import qs from 'qs';
/**
 * @method connectBzbsGet
 * @method connectBzbsPost
 * @method connectBzbsGetBlob
 */
export default {
  /**
   * Connect Buzzebees GET method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {string} key(optional)
   * @param {object} data (optional)
   * @return Promise
   */
   config: function() {
    return Vue.bzbsConfig;
  },
  connectBzbsGet: function (url, token, data) {
    var header = {
      'App-Id': Vue.bzbsConfig.client_id,
    }
    if (data == null) {
      data = {}
    }
    // data.device_app_id = this.config().client_id;
    if (token != null) {
      header.Authorization = 'token ' + token
    }
    return axios({
      method: 'get',
      url: url,
      params: data,
      headers: header,
    })
  },

  connectDataService: function (url, key, data) {
    var header = {
      'x-functions-key': key,
    }
    console.log(key)
    if (data == null) {
      data = {}
    }
    // data.device_app_id = this.config().client_id;

    return axios({
      method: 'get',
      url: url,
      params: data,
      headers: header,
    })
  },
  /**
   * Connect Buzzebees POST method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data parameter
   * @param {string} uploadKey (optional)
   * @param {object} uploadfile (optional)
   * @return Promise
   */
  connectBzbsPost: function (url, token, data, uploadKey, uploadfile) {
    var header = {
      'App-Id': Vue.bzbsConfig.client_id,
    }
    // data.device_app_id = this.config().client_id;
    var form = this.arrToFormData(data)
    if (uploadKey) form.append(uploadKey, uploadfile)

    if (token != null) {
      header.Authorization = 'token ' + token
    }

    return axios({
      method: 'post',
      url: url,
      data: form,
      headers: header,
    })
  },

  connectBzbsPostParams: function (url, token, data) {
    var header = {
      'App-Id': Vue.bzbsConfig.client_id,
    }
    // data.device_app_id = this.config().client_id;

    if (token != null) {
      header.Authorization = 'token ' + token
    }

    return axios({
      method: 'post',
      url: url,
      params: data,
      headers: header,
    })
  },

  connectBzbsPostEncode: function (url, token, data) {
    var header = {
      'App-Id': Vue.bzbsConfig.client_id,
      'content-type': 'application/x-www-form-urlencoded',
    }

    const qs = Object.keys(data)
      .map(key => `${key}=${encodeURIComponent(data[key])}`)
      .join('&')

    if (token != null) {
      header.Authorization = 'token ' + token
    }

    return axios({
      method: 'post',
      url: url,
      data: qs,
      headers: header,
    })
  },

  
  connectBzbsPostEncodeBody: function (url, token, data) {
    var header = {
      'App-Id': Vue.bzbsConfig.client_id,
      'content-type': 'application/x-www-form-urlencoded',
    } 
    
    if (token != null) {
      header.Authorization = 'token ' + token
    }

    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      headers: header,
    })
  },

  /**
   * Convert data to array of FormData
   * @param {Array|Dictionary} arr
   */
  arrToFormData: function (arr) {
    var form = new FormData()
    for (var i in arr) {
      form.append(i, arr[i])
    }
    return form
  },

  /**
   * Connect Buzzebees GET Blob
   * @param {string} url string url
   * @return Promise
   */
  connectBzbsGetBlob: function (url) {
    return axios({
      method: 'get',
      url: url + '?v=' + new Date().getTime(),
    })
  },
  connectBzbsDelete: function (url, token, data) {
    var header = {
      'App-Id': Vue.bzbsConfig.client_id,
    }
    if (token != null) {
      header.Authorization = 'token ' + token
    }

    return axios({
      method: 'delete',
      url: url,
      params: data,
      headers: header,
    })
  },
}
