export default {
    install (Vue) {
        Vue.ga = {
            // ========== normal function ===========
            /**
             * Track screen name
             * @param {Vue} vue - (Require) Vue object
             * @param {string} name
             */
            screenName: function(vue, name) {
                vue.$ga.screenview(name);
                vue.$ga.page({
                    page: name,
                    title: name,
                    location: window.location.href
                });

                try {
                    window.BzbsAnalytic.trackScreen(name);
                } catch(e) {
                    console.log(e);
                }
            },

            /**
             * Track event
             * @param {Vue} vue - (Require) Vue object
             * @param {string} category - not null
             * @param {string} action - not null
             * @param {string} label - optional
             * @param {string} value - optional
             */
            event: function(vue, category, action, label, value) {
                vue.$ga.event(category, action, label, value);

                try {
                    window.bzbAnalytic.trackEvent(category, action, label, value);
                } catch(e) {
                    console.log(e);
                }
            },

            /******* หมายเหตุ โค้ดด้านล่างนี้ยังไม่เคยเทส ฝากเทสด้วย *******/

            // ============ Dashbaord =========
            /**
             * Track dashboard
             * @param {Vue} vue - (Require) Vue object
             * @param {string} category - not null
             * @param {string} action - not null
             * @param {Object} dashboardItem - Dashboard object (not null)
             * @param {string} value - optional
             */
            dashboard: function(vue, category, action, dashboardItem, value) {
                var type = dashboardItem.type || dashboardItem.mode;
                switch (type) {
                    case "campaign":
                        if (dashboardItem.id) {
                            this.event(vue, category, action, dashboardItem.id + "|" + dashboardItem.strGA);
                        }
                        break;
                    case "cat":
                        var _catId = dashboardItem.cat || dashboardItem.id || "";
                        if (_catId) {
                            this.event(vue, category, action, _catId + "|" + dashboardItem.strGA);
                        }
                        break;
                    case "campaign_rotate":
                        var _campaign_rotage = dashboardItem.subCampaignDetails;
                        if (_campaign_rotage && _campaign_rotage.length > 0) {
                            for (var campaign of _campaign_rotage) {
                                this.event(vue, category, action, campaign, value);
                            }
                        }
                        break;
                    case "link":
                        this.event(vue, category, action);
                        break;

                    case "none":
                        this.event(vue, category, action);
                        break;
                    case "menu":
                    case "event":
                        break;
                }
            },

            /**
             * Track dashboard list
             * @param {Vue} vue - (Require) Vue object
             * @param {string} category - not null
             * @param {string} action - not null
             * @param {Array} dashboardList - Dashboard list (not null)
             * @param {string} value - optional
             */
            dashboardList: function(vue, category, action, dashboardList, value) {
                for (var dashboard of dashboardList) {
                    this.dashboard(vue, category, action, dashboard, value);
                }
            },

            // ========= Campaign ==========
            /**
             * Track campaign item
             * @param {Vue} vue - (Require) Vue object
             * @param {string} category - not null
             * @param {string} action - not null
             * @param {Object} campaign - Campaign object (not null)
             * @param {string} value - optional
             */
            campaign: function(vue, category, action, campaign, value) {
                this.event(vue, category, action, campaign.id + '|' + campaign.name, value);
            },

            /**
             * Track campaign list
             * @param {Vue} vue - (Require) Vue object
             * @param {string} category - not null
             * @param {string} action - not null
             * @param {Array} campaignList - Campaign list (not null)
             * @param {string} value - optional
             */
            campaignList: function(vue, category, action, campaignList, value) {
                for (var campaign of campaignList) {
                    this.campaign(vue, category, action, campaign, value);
                }
            },

            // ========= Category ==========
            /**
             * Track category
             * @param {Vue} vue
             * @param {string} category
             * @param {string} action
             * @param {Object} catObject
             * @param {boolean} trackWithName
             */
            category: function(vue, category, action, catObject, trackWithName) {
                if (trackWithName) {
                    this.event(vue, category + catObject.name, action + catObject.name, catObject.id + '|' + catObject.name);
                } else {
                    this.event(vue, category, action, catObject.id + '|' + catObject.name);
                }
            },

            /**
             * Track category list
             * @param {Vue} vue
             * @param {string} category
             * @param {string} action
             * @param {Array} catObjectList
             * @param {boolean} trackWithName
             */
            categoryList: function(vue, category, action, catObjectList, trackWithName) {
                for (var cat of catObjectList) {
                    this.category(vue, category, action, cat, trackWithName);
                }
            },
        }
    }
}
