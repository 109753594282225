import Vue from 'vue'
import { store } from './store'
// import Vuex from 'vuex';
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import router from './router'
import Loading from '@/components/Layout/Loading'
import VueCookies from 'vue-cookies'
import BootstrapVue from 'bootstrap-vue'

import Config from '@/config/vueconfig.js'
import Validator from '@/plugins/Validator.js'
import Vue2Editor from 'vue2-editor'
import ga from '@/plugins/GA.js'
import BBEnumCampaignType from '@/plugins/BBEnumCampaignType.js'
import RegExp from '@/plugins/RegExp.js'
import Filter from '@/plugins/Filter.js'
import Account from '@/helper/AccountHelper.js'
import VueApexCharts from 'vue-apexcharts'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import ConfigHelper from '@/helper/Config.js'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import vPlayBack from 'v-playback'

// Cookie Accept
import TurnOffAutocomplete from 'vue-turn-off-autocomplete'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VueCookies)
Vue.use(Config)
Vue.use(Validator)
Vue.use(ga)
Vue.use(BBEnumCampaignType)
Vue.use(RegExp)
Vue.use(Filter)
Vue.use(BootstrapVue)
Vue.use(VueApexCharts)
Vue.use(TurnOffAutocomplete)
Vue.use(vPlayBack)
Vue.use(Vue2Editor)
Vue.use(VueGoogleMaps, {
  load: {
    key: Vue.bzbsConfig.businessRule.key.googleMapApiKey,
    libraries: 'places',
  },
  installComponents: true,
})

Vue.component('Loading', Loading)
Vue.component('apexchart', VueApexCharts)
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

router.beforeEach((to, from, next) => {
  if (to.path == '/vue/') {
    if (to.hash != '' && to.hash != null) {
      let path = window.location.href.split('#')[1]
      if (path != '' && path != null) {
        window.location = window.location.origin + path
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  mixins: [Mixin],
  router,
  store,
  watch: {
    $route(to, from) {
      if (!to.hash && to.name !== from.name) {
        this.$store.commit('Loading', true)
        this.handleDomain()
        if (
          to.name != 'Login' &&
          to.name != 'ForgotPassword' &&
          to.name != 'ResetPassword' &&
          to.name != 'RegisterVue' &&
          to.name != 'Package' &&
          to.name != 'ErrorConfig'
        ) {
          let configSelfOnBoard = false
          this.checkAzureConfig()
          try {
            let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
            if (parse) {
              configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
                ? parse.buzzebeesConfigSelfOnBoard
                : false
            } else {
              configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
            }
          } catch (e) {
            configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
          }

          if (configSelfOnBoard) {
            if (!Cache.get('CRMUser') || !Cache.get('CRMPackage')) {
              Account.setLogout()
              this.$router.push({ name: 'Login' })
            } else if (Cache.get('CRMPackage')) {
              let requirePackage = Cache.get('CRMPackage').RequirePackage
              if (requirePackage) {
                let extend = Cache.get('CRMPackage').IsExtendExpired
                if (extend != undefined) {
                  if (extend) {
                    this.handleLoading(false)
                    this.$router.push({
                      name: 'Package',
                      query: { returnPath: 'Dashboard' },
                    })
                  }
                } else {
                  Account.setLogout()
                  this.$router.push({ name: 'Login' })
                }
              }
            }
          } else {
            if (!Cache.get('CRMUser')) {
              Account.setLogout()
              this.$router.push({ name: 'Login' })
            }
          }
        }
      }
      document.body.classList.remove('disable-serial')
    },
  },
  render: h => h(App),
  created: function () {
    this.checkAzureConfig()
    Vue.initFinished = false
    Account.initial().then(() => {
      this.handleLoading(true)
      if (this.lbl == null) {
        this.$router.go()
      } else {
        this.handleLoading(false)
      }
    })
  },
  methods: {
    checkAzureConfig() {
      if (Vue.bzbsConfig.businessRule.open.Azure) {
        var localhost = false
        if (window.location.hostname == 'localhost') {
          localhost = true
        } else {
          localhost = false
        }
        ConfigHelper.getVersion(localhost).then(response => {
          let apiSetting = response.apiSetting
          let configSetting = response.configSetting
          let linkConnect = response.linkConnect
          localStorage.setItem('bzbsCRMConfig', JSON.stringify(configSetting))

          if (apiSetting) {
            let errorArrayAPI = []

            Object.keys(apiSetting).forEach(i => {
              if (apiSetting[i] == null || apiSetting[i] == '') {
                errorArrayAPI.push(i)
              }
            })
            if (
              errorArrayAPI.length > 0 &&
              window.location.pathname !== '/PageNotFound/ErrorConfig'
            ) {
              var error = null
              Object.keys(errorArrayAPI).forEach(i => {
                error =
                  typeof error == 'string'
                    ? error.concat(' / ' + errorArrayAPI[i])
                    : errorArrayAPI[i]
              })

              this.$router.push({
                name: 'ErrorConfig',
                params: { APISetting: errorArrayAPI },
              })
            } else {
              if (apiSetting.buzzebeesApiDataService) {
                Vue.bzbsConfig.dataservice = apiSetting.buzzebeesApiDataService
              }
              if (apiSetting.buzzebeesApiAnalyticUrl) {
                Vue.bzbsConfig.bzbsAnalyticUrl =
                  apiSetting.buzzebeesApiAnalyticUrl
              }
              if (apiSetting.buzzebeesApiEdmUrl) {
                Vue.bzbsConfig.bzbsEdmUrl = apiSetting.buzzebeesApiEdmUrl
              }
              if (apiSetting.buzzebeesApiUrl) {
                Vue.bzbsConfig.bzbsUrl = apiSetting.buzzebeesApiUrl
              }
              if (apiSetting.buzzebeesApiModuleUrl) {
                Vue.bzbsConfig.bzbsModuleUrl = apiSetting.buzzebeesApiModuleUrl
              }
              if (apiSetting.buzzebeesApiBlobUrl) {
                Vue.bzbsConfig.bzbsBlobUrl = apiSetting.buzzebeesApiBlobUrl
              }
              if (apiSetting.buzzebeesApiServicUrl) {
                Vue.bzbsConfig.buzzebeesApiServicUrl =
                  apiSetting.buzzebeesApiServicUrl
              }
              if (apiSetting.buzzebeesApiConsumerUrl) {
                Vue.bzbsConfig.bzbsConsumerUrl =
                  apiSetting.buzzebeesApiConsumerUrl
              }
              if (apiSetting.buzzebeesApiReportUrl) {
                Vue.bzbsConfig.dataReportUrl = apiSetting.buzzebeesApiReportUrl
              }
              if (apiSetting.buzzebeesApiWallet) {
                Vue.bzbsConfig.bzbsApiWallet = apiSetting.buzzebeesApiWallet
              }
              if (apiSetting.buzzebeesApiShopingCartUrl) {
                Vue.bzbsConfig.shopingCartUrl =
                  apiSetting.buzzebeesApiShopingCartUrl
              }
              if (apiSetting.buzzebeesApiShopingModuleCartUrl) {
                Vue.bzbsConfig.shopingmoduleCartUrl =
                  apiSetting.buzzebeesApiShopingModuleCartUrl
              }
              if (apiSetting.buzzebeesApiShippingModuleUrl) {
                Vue.bzbsConfig.shippingmoduleUrl =
                  apiSetting.buzzebeesApiShippingModuleUrl
              }
            }
          }
          if (configSetting) {
            if (configSetting.buzzebeesConfigSelfOnBoard != null) {
              Vue.bzbsConfig.businessRule.open.package =
                configSetting.buzzebeesConfigSelfOnBoard
            }
            if (configSetting.buzzebeesConfigSelfOnBoardPayment != null) {
              Vue.bzbsConfig.businessRule.open.payment =
                configSetting.buzzebeesConfigSelfOnBoardPayment
            }
            if (configSetting.featureFacebook != null) {
              Vue.bzbsConfig.businessRule.open.facebook =
                configSetting.featureFacebook
            }
            if (configSetting.smarttargetNewVersion != null) {
              Vue.bzbsConfig.businessRule.open.smarttarget_v2 =
                configSetting.smarttargetNewVersion
            }
          }
          if (linkConnect) {
            if (linkConnect.linkVideoConnectLine) {
              Vue.bzbsConfig.businessRule.link.connectLine =
                linkConnect.linkVideoConnectLine
            }
          }
        })
      }
    },
  },
}).$mount('#app')
