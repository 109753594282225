import Vue from 'vue'
import Vuex from 'vuex'
import Account from '@/helper/AccountHelper.js'
Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    auth: null,
    isAppLoad: true,
    isDashboard: false,
    isFooterMenu: false,
    artWork: [],
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth
    },
    Loading(state, value) {
      state.isAppLoad = value
    },
    Dashboard(state, value) {
      state.isDashboard = value
    },
    FooterMenu(state, value) {
      state.isFooterMenu = value
    },
    SET_SMARTTARGET(state, value) {
      state.setSmartTargetBlob = {...value}
    },
    SET_ARTWORK(state, value) {
      state.artWork = value
    },
  },
  actions: {
    initial({ commit }) {
      let auth = null
      if (Account.getAuth()) {
        try {
          auth = Account.getAuth()
        } catch (err) {
          console.log('actions initial error', err)
        }
      }
      commit('setAuth', auth)
    },
    setSelectedSmartTarget(context, payload) {
      context.commit('SET_SMARTTARGET', payload)
    },
    setArtWork(context, payload) {
      context.commit('SET_ARTWORK', payload)
    },
  },
  getters: {
    getSmartTargetBlob: function (state) {
      return state.setSmartTargetBlob
    },
    getArtwork: function (state) {
      return state.artWork
    },
  },
})
