import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'

/**
 * @method getShopSetting
 * @method postShopSetting
 * @method syncApplicationPermission
 */

export default {
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  app_id: function () {
    return Cache.get('CRMUser').AppId
  },
  agency_id: function () {
    return Vue.bzbsConfig.agency_id
      ? Vue.bzbsConfig.agency_id
      : Cache.get('CRMUser').AgencyId
  },
  getShopSetting: function () {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/check_shop_setting?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  postShopSetting: function (obj) {
    let params = {
      agencyId: this.agency_id(),
      accountcompleted: obj.accountcompleted,
      brandcompleted: obj.brandcompleted,
      connectcompleted: obj.connectcompleted,
      usercompleted: obj.usercompleted,
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/shop_setting'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  syncApplicationPermission: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusoffice/bypass_upsert_applications'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
