import Vue from 'vue'
export default {
  /**
   *
   * @method get
   * @method set
   * @method remove
   * @method removePopCache
   *
   */

  get(key) {
    return Vue.$cookies.get(key) || null
  },
  set(key, value) {
    Vue.$cookies.set(key, value, null, null, null, true, "Strict")
  },
  remove(key) {
    Vue.$cookies.remove(key)
  },
}
